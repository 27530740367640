import { IconButton } from '@mui/material';
import React from 'react';
import CustomTable from '../../components/common/customTable';

import { ReactComponent as DownloadIcon } from '../../assets/icons/svg/file_download.svg';


const BillingPage = ({ id, data }) => {
  const columns = [
    { header: 'ID', field: 'id' },
    { header: 'Date due', field: 'date_due' },
    { header: 'Amount', field: 'amount' },
    { header: 'Status', field: 'status' },
    {
      header: 'Invoice',
      render: (row) => (
        <IconButton>
          <DownloadIcon />
        </IconButton>
      ),
    },
  ];

  return <CustomTable id={id} columns={columns} data={data} />;
};

export default BillingPage;
