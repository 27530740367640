import React, { useEffect, useState } from 'react';
import { MenuItem, IconButton, InputAdornment, Box } from '@mui/material';
import { ReactComponent as DomainLinkIcon } from '../../assets/icons/svg/carbon_link.svg';
import { makeStyles } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { setDomain } from '../../redux/domainData/domainActions';

import { setIsRefresh } from '../../redux/loader/loadingActions';
import CONSTANTS from '../../constants/constants';
import Duration from './Duration';
import CustomSelect from '../../components/common/customSelect';
import CustomDurationSelect from '../../components/common/customDate/CustomDurationSelect';
import DurationPicker from '../../components/common/customDate/DurationPicker';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    border:'1px solid blue',

  },
  refreshBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 85,height:45,
    border: '1px solid #0046FF',
    borderRadius: '0.5rem',
    backgroundColor:'#FFFFFF'

  },

}));




const DomainControl = ({id,domain,setPage}) => {
  const classes = useStyles();
  const [selectedDomain, setSelectedDomain] = useState<any>({});

  const {domainsByClient} = useSelector((state: any) => state?.domainData);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedItem = JSON.parse(sessionStorage.getItem(CONSTANTS.DOMAIN_OBJ) || '{}');
    if (storedItem?.domain_id) {
      setSelectedDomain(storedItem);
    } else {
      setSelectedDomain(domain);
    }

  }, [domain,domain?.domain_id]);

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDomainObj = event?.target?.value
    setSelectedDomain(JSON.parse(selectedDomainObj));
    sessionStorage.setItem(CONSTANTS.DOMAIN_OBJ, selectedDomainObj);
    dispatch(setDomain(JSON.parse(selectedDomainObj)));
    setPage(1)
  };


  
  return (
     <Box id={id} display="flex" gap={2} alignItems="center">
      <CustomSelect
        id={`${id}-textField`}
        options={domainsByClient?.map((eachDomain) => ({
          value: JSON.stringify(eachDomain),
          label: eachDomain?.domain_url,
        }))}
        value={JSON.stringify(selectedDomain)}
        onChange={handleDomainChange}
        startAdornment={
          <InputAdornment position="start">
            <DomainLinkIcon />
          </InputAdornment>
        }
      />
      <Box sx={{width:{sm:'100%',md:'70%',lg:'72%'}}}><DurationPicker setPage={setPage}/></Box>
      {/* <Box sx={{width:'60%'}}><CustomDurationSelect setPage={setPage}/></Box> */}
      {/* <Duration id={'timeDurationSelect'} setPage={setPage}/> */}
      {/* <Box id={'refreshButtonBox'} className={classes.refreshBox}>
        <IconButton title='Refresh' id="refreshButton" onClick={handleRefreshClick} size="medium">
          <Refresh />
        </IconButton>
      </Box> */}
    </Box>
   
     
  );
};

export default DomainControl;
