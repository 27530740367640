import { debounce } from 'lodash';
import CONSTANTS from "../constants/constants";
import { 
    DOMAINS_BY_CLIENT_ENDPOINT, 
    getAttacksByCategoryEndpoint, 
    getAttacksBySeverityEndpoint, 
    getAttacksInformationEndpoint, 
    getAttacksTrednlineEndpoint, 
    getCombinedResponseEndpoint, 
    getGeoMapEndPoint, 
    getHitsAndAttacksEndpoint, 
    getUserRequestStatsEndpoint, 
    getViewDetailsEndpoint 
} from "../constants/endpoints";
import { setDomain, setDomainsByClient } from "../redux/domainData/domainActions";
import { 
    setAttackInformationData, 
    setAttacksByCategory, 
    setAttacksBySeverity, 
    setAttacksTrendlineData, 
    setCombinedResponse, 
    setGeoMapData, 
    setRequestStats, 
    setStatsData 
} from "../redux/ui/uiActions";
import { convertIntoArray } from "../screen/utils/convertIntoArray";
import { request } from "./request";
import { initialStatsData } from "../components/initial/InitialStatsData";
import { setAttackTrendlineLoader, setAttacksByCategoryLoader, setAttacksBySeverityLoader, setAttacksInformationLoader, setCombinedResponseLoader, setGeoLocationLoader, setHitsAndAttacksLoader, setUserRequestStatsLoader } from '../redux/loader/loadingActions';
import dayjs from 'dayjs';
import { convertDateTimeString, convertToUTCFormat, formatAndEncodeDate, getDateTimeForLabel, getDaysDifference } from '../screen/utils/convertTime';
import { initialUiState } from '../redux/ui/uiReducer';
import moment from 'moment';

export const domainsByClientApi = async(dispatch) => {
    try {
        const DOMAINS_BY_CLIENT_URL = `${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${DOMAINS_BY_CLIENT_ENDPOINT}`;
        const response: any = await request.get(DOMAINS_BY_CLIENT_URL);

        if (response?.success) {
            dispatch(setDomain(response?.data[0]));
            dispatch(setDomainsByClient(response?.data));
        } else {
            dispatch(setDomain({}));
            dispatch(setDomainsByClient([]));
        }
    } catch(error) {
        console.log("error ", error);
    }
};

export const hitsAndAttacksApi = debounce(async (dispatch, domainId, startAndEndDateTime) => {
    dispatch(setHitsAndAttacksLoader(true));
    const HITS_AND_ATTACKS_URL = `${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${getHitsAndAttacksEndpoint(domainId, startAndEndDateTime)}`;
    
    try {
        dispatch(setStatsData(initialStatsData?.map(stat=>{
            return {
                ...stat,
                isLoading:true
            }
        })));
        const response: any = await request.get(HITS_AND_ATTACKS_URL);

        if (response?.success && Array.isArray(response?.data)) {
            const updatedStatsData = initialStatsData?.map(stat => {
                const apiData = response?.data?.find(item => item[stat?.id] !== undefined);
                if (apiData) {
                    return {
                        ...stat,
                        value: apiData[stat?.id],
                        growthRate: apiData?.growth_rate,
                        isLoading:false
                    };
                }
                return stat;
            });

            dispatch(setStatsData(updatedStatsData));
        } else {
            console.warn('Unexpected API response structure:', response);
            dispatch(setStatsData(initialStatsData?.map(stat=>{
                return {
                    ...stat,
                    isLoading:false
                }
            })));
        }
    } catch (error) {
        console.error("API request error:", error);
        dispatch(setStatsData(initialStatsData?.map(stat=>{
            return {
                ...stat,
                isLoading:false
            }
        })));
    } finally {
        dispatch(setHitsAndAttacksLoader(false));
    }
}, 400);

export const geoLocationApi = debounce(async (dispatch, domainId, startAndEndDateTime, filterType) => {
    dispatch(setGeoLocationLoader(true));
    const LOCATION_END_POINT = getGeoMapEndPoint(domainId, startAndEndDateTime, filterType);
    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${LOCATION_END_POINT}`);

        if (response?.success) {
            dispatch(setGeoMapData(response?.data?.geo_map_details));
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(setGeoMapData([]));
    } finally {
        dispatch(setGeoLocationLoader(false));
    }
}, 500);

const severityLevels = ['High', 'Medium', 'Low'];

export const AttackTypesBySeverityApi = debounce(async(dispatch, domainId, startAndEndDateTime) => {
    dispatch(setAttacksBySeverityLoader(true));
    const ATTACKS_BY_SEVERITY_ENDPOINT = getAttacksBySeverityEndpoint(domainId, startAndEndDateTime);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ATTACKS_BY_SEVERITY_ENDPOINT}`);

        if (response?.success) {
            const updatedData = severityLevels?.map((severity) => {
                const responseItem = response?.data?.severity_details?.find((resItem) => resItem?.name === severity);
                return responseItem || { value: 0, name: severity, percentage: 0 };
            });              

            dispatch(setAttacksBySeverity(updatedData));
        }
    } catch(error) {
        console.log("error ", error);
    } finally {
        dispatch(setAttacksBySeverityLoader(false));
    }
}, 600);

export const fetchAttackTypesByCategoryApi = debounce(async(dispatch, domainId, startAndEndDateTime) => {
    dispatch(setAttacksByCategoryLoader(true));
    const ATTACKS_BY_CATEGORY_ENDPOINT = getAttacksByCategoryEndpoint(domainId, startAndEndDateTime);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ATTACKS_BY_CATEGORY_ENDPOINT}`);

        if (response?.success) {
            dispatch(setAttacksByCategory(response?.data?.attacks_by_category_data));
        }
    } catch(error) {
        console.log("error ", error);
    } finally {
        dispatch(setAttacksByCategoryLoader(false));
    }
}, 600);

export const attackTrendLineChartApi = debounce(async(dispatch, domainId, startAndEndDateTime, timeline) => {
   
    // const startAndEndDate = getDateTimeForLabel(timeline)

    // const startAndEnd = {from:startAndEndDateTime.from,to:encodedDatetime}
    // console.log("startAndEnd",startAndEnd)
    // console.log("encodedDatetime ",encodedDatetime)
    dispatch(setAttackTrendlineLoader(true));
    const ATTACKS_TRENDLINE_ENDPOINT = getAttacksTrednlineEndpoint(domainId, startAndEndDateTime, timeline);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ATTACKS_TRENDLINE_ENDPOINT}`);


        
        const diffDays = getDaysDifference(startAndEndDateTime.from, startAndEndDateTime.to);
                                            
        const lastDate = response?.data[response?.data.length - 1].end_time;
        const lastTime = dayjs(lastDate);
        const firstObjStartTime = response?.data[0].start_time;
        const firstObjEndTime = response?.data[0].end_time;

        const startTime = dayjs(firstObjStartTime);
        const endTime = dayjs(firstObjEndTime);

        const isNotSameHour =  startTime.hour() !== endTime.hour()
        const isNotSameDateOrMonth =  startTime.date() !== endTime.date() || startTime.month() !== endTime.month()

        // console.log("date and month ",startTime.date(),endTime.date())
        // console.log("hour ",startTime.hour() !== endTime.hour())
        // console.log("date ",startTime.date() !== endTime.date())
        // console.log("month ",startTime.month() !== endTime.month())
        // console.log("both ",(startTime.date() !== endTime.date() && startTime.month() !== endTime.month()))
        let nextTime;

        let updatedData = [...response?.data]


        const firstObject = {
            "total_count": 0,
            "attacks": 0,
            "legit": 0,
            "start_time":firstObjStartTime,
            "end_time": firstObjStartTime
  
        }



        // Determine the amount of time to add based on the timeline
        switch (timeline) {
        case '12h':
        case '24h':
            nextTime = lastTime.add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ');

            const lastObject = {
                "total_count": 0,
                "attacks": 0,
                "legit": 0,
                "start_time":lastDate,
                "end_time": nextTime
      
            }
            updatedData = [...response?.data,lastObject]

            if(isNotSameHour){
                updatedData.splice(0, 0, firstObject);
            }

            break;
        case 'custom':
            if(diffDays < 2){
                nextTime = lastTime.add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ');

                const lastObject = {
                    "total_count": 0,
                    "attacks": 0,
                    "legit": 0,
                    "start_time":lastDate,
                    "end_time": nextTime
            
                }
                    updatedData = [firstObject,...response?.data,lastObject]
                
                }
            else{
                if(isNotSameDateOrMonth){
                    updatedData = [firstObject,...response?.data]

                }else{
                    updatedData = [...response?.data]

                }

            }
        break;

            
        case 'week':
            if(isNotSameDateOrMonth){
                updatedData.splice(0, 0, firstObject);

            }
            break;
        // case 'month':
        //     nextTime = lastTime.add(1, 'month').format('YYYY-MM-DDTHH:mm:ssZ');;
        //     break;
        case 'year':
            if(isNotSameDateOrMonth){
                updatedData.splice(0, 0, firstObject);
            }
            break;
        default:

            updatedData = [...response?.data]

            // nextTime = lastTime.format('YYYY-MM-DDTHH:mm:ssZ');; // No change if timeline doesn't match any case
        }


        if (response?.success) {

            dispatch(setAttacksTrendlineData(updatedData));
        }

        } catch(error) {
            console.log("error ", error);
        } finally {
            dispatch(setAttackTrendlineLoader(false));
        }
}, 600);

export const attacksInformationApi = debounce(async(dispatch, page, domainId, startAndEndDateTime, searchTerm,countriesFilter,severitiesFilter) => {
    
    // console.log("in attack info api ",startAndEndDateTime)
    dispatch(setAttacksInformationLoader(true));
    const countryFilter = (countriesFilter?.length>0)?`&country_filter=${countriesFilter}`:''
    const severityFilter = (severitiesFilter?.length>0)?`&severity_filter=${severitiesFilter}`:''


    const ATTACKS_INFORMATION_ENDPOINT = getAttacksInformationEndpoint(domainId, startAndEndDateTime, page, searchTerm);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ATTACKS_INFORMATION_ENDPOINT}${countryFilter}${severityFilter}`);

        if (response?.success) {
           dispatch(setAttackInformationData({
                data: response?.data?.attack_information_details,
                total: response?.data?.total
            }));
        }
    } catch(error) {
        console.log("error ", error);
    } finally {
        dispatch(setAttacksInformationLoader(false));
    }
}, 800);

export const userRequestStatsApi = debounce(async (dispatch, domainId, startAndEndDateTime) => {
    dispatch(setUserRequestStatsLoader(true));
    const USER_REQUEST_STATS_ENDPOINT = getUserRequestStatsEndpoint(domainId, startAndEndDateTime);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${USER_REQUEST_STATS_ENDPOINT}`);

        const statsData = {
            responseCodes: convertIntoArray(response?.data?.top_response_codes),
            owaspTop10Mapping: convertIntoArray(response?.data?.owasp_top_10),
            userAgents: convertIntoArray(response?.data?.top_user_agents),
            uriHits: convertIntoArray(response?.data?.top_uris),
            requestMethods: convertIntoArray(response?.data?.top_request_methods),
        };

        if (response?.success) {
            dispatch(setRequestStats(statsData));
        }
    } catch(error) {
        console.log("error ", error);
    } finally {
        dispatch(setUserRequestStatsLoader(false));
    }
}, 500);

export const viewMoreAttackInfoApi = async (domainId, uniqueId,startAndEndDate) => {
    const VIEW_ATTACK_DETAILS_ENDPOINT = getViewDetailsEndpoint(domainId, uniqueId,startAndEndDate);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${VIEW_ATTACK_DETAILS_ENDPOINT}`);

        if (response?.success) {
            return response;
        }

        return null;
    } catch(error) {
        console.log("error ", error);
    }
};


export const combinedResponsesApi = debounce(async (dispatch, domainId, startAndEndDateTime) =>{
    

    dispatch(setCombinedResponseLoader(true))

    const COMBINED_API_ENDPOINT = getCombinedResponseEndpoint(domainId, startAndEndDateTime)

    try{

        dispatch(setCombinedResponse({
            ...initialUiState?.combinedResponse,
        }))

        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${COMBINED_API_ENDPOINT}`);

        if(response?.success){  

            const updatedCardsData = initialStatsData?.map(stat => {
                const apiData = response?.data?.cards_data?.find(item => item[stat?.id] !== undefined);
                if (apiData) {
                    return {
                        ...stat,
                        value: apiData[stat?.id],
                        // growthRate: apiData?.growth_rate,
                        isLoading:false
                    };
                }
                return stat;
            });

        const updatedGeoMapData = {
            legit:response?.data?.geo_map_data?.legit,
            attack:response?.data?.geo_map_data?.attack,
        }

        const updatedSeverityData = {
            totalCount:response?.data?.severity_details.total_count,
            attacksBySeverity: severityLevels ?.map((severity) => {
                const responseItem = response?.data?.severity_details?.severity_details?.find((resItem:any) => resItem?.name === severity);
                return responseItem || { value: 0, name: severity, percentage: 0 };
            })
        }


        let updatedAttackByCategory = []

        if (response?.data?.attacks_by_category_data !== null && response?.data?.attacks_by_category_data !== undefined 
        && Array.isArray(response?.data?.attacks_by_category_data)) {
            updatedAttackByCategory = response?.data?.attacks_by_category_data
        }

        const updatedRequestStats = {
            responseCodes: convertIntoArray(response?.data?.top_response_codes),
            owaspTop10Mapping: convertIntoArray(response?.data?.owasp_top_10),
            userAgents: convertIntoArray(response?.data?.top_user_agents),
            uriHits: convertIntoArray(response?.data?.top_uris),
            requestMethods: convertIntoArray(response?.data?.top_request_methods),
        }

        const combinedResponseData = {
            cardsData:updatedCardsData,
            geoMapData:updatedGeoMapData,
            severityDetails:updatedSeverityData,
            attacksByCategoryData:updatedAttackByCategory,
            requestStats:updatedRequestStats
        }


        dispatch(setCombinedResponse(combinedResponseData))

        }else{
            dispatch(setCombinedResponse({
                ...initialUiState?.combinedResponse,
                cardsData:initialStatsData?.map(stat=>{
                    return {
                        ...stat,
                        isLoading:false
                    }
                })
    
            }))
        }

    }
    catch(error){
        dispatch(setCombinedResponse({
            ...initialUiState?.combinedResponse,
            cardsData:initialStatsData?.map(stat=>{
                return {
                    ...stat,
                    isLoading:false
                }
            })

        }))
        console.log("error ", error);

    } finally {
        dispatch(setCombinedResponseLoader(false))
    }

},100)
