// import { IconButton, Popover, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import CONSTANTS, { URL_PATTERN } from "../../../constants/constants";
// import { addOrEditOrDeleteClient } from "./clientServices";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { useFormik } from "formik";
// import * as Yup from 'yup';
// import { pushNotification } from "../../../redux/notification/notificationActions";
// import moment from "moment";

// interface AddDomainToClientProps {
//   clientId: string;
//   handleGetPaginatedClients?: any;
//   initialValues?: any;  // New prop to accept prefilled values for editing
//   isEditing?: any;  // New prop to toggle between add/edit mode
//   onClose?: any;
//   anchorEl?: HTMLElement | null;
//   handleAddDomainBtnClick?: any;
// }

// const EditDomain: React.FC<AddDomainToClientProps> = ({ clientId, onClose,handleGetPaginatedClients, anchorEl,initialValues, isEditing}) => {


//   const dispatch = useDispatch();

//   const defaultValues = {
//     client_id: clientId,
//     domain_name: "",
//     domain_url: "",
//     db_name: "",
//     ip_address: "",
//     current_mode: "Off",
//     hosting_service_name: "AWS",
//     dns_mapped: true,
//     mapped_on: moment().format('DD/MM/YYYY HH:mm:ss'),
//   };

//   const formikInitialValues = initialValues || defaultValues;

//   const formPayload = (values: any) => ({
//     domain_name: values?.domain_name,
//     domain_url: values?.domain_url,
//     ip_address: values?.ip_address,
//     current_mode: values?.current_mode,
//     hosting_service_name: values?.hosting_service_name,
//     dns_mapped: values?.dns_mapped,
//     mapped_on: values?.mapped_on,
//     client_id: values?.client_id,
//   });

//   const postFormValues = (values: any) => {
//     console.log("post form values ",values)
//     const postData = formPayload(values);
//     handlePostApiRequest(postData);
//   };


//   const handlePrefillData = async () => {
//    formOne.setFieldValue("domain_name", initialValues?.domain_name);
//    formOne.setFieldValue("domain_url", initialValues.domain_url);
//    formOne.setFieldValue("ip_address", initialValues.ip_address);
//    formOne.setFieldValue("current_mode", initialValues.current_mode);
//    formOne.setFieldValue("hosting_service_name", initialValues.hosting_service_name);
//    formOne.setFieldValue("dns_mapped", initialValues.dns_mapped);
//     };

//   useEffect(() => {
//       handlePrefillData();
//   }, []);

//   const handlePostApiRequest = async (data: any) => {
//     console.log("data in post ",data)
//     const actionType = "edit-domain"  // Use the appropriate action type
//     const response: any = await addOrEditOrDeleteClient(actionType, null, data);

//     if (response?.success) {
//     //   handleClosePopover();
//       handleGetPaginatedClients();
//       dispatch(
//         pushNotification({
//           isOpen: true,
//           message: response?.message,
//           type: CONSTANTS.SUCCESS,
//         })
//       );
//     } else {
//     //   handleClosePopover();
//       dispatch(
//         pushNotification({
//           isOpen: true,
//           message: response?.message,
//           type: CONSTANTS.ERROR,
//         })
//       );
//     }
//   };

//   const handleSubmit = async (values: any, setSubmitting: any) => {
//     console.log("handleSubmit called"); // Debugging log
//     setSubmitting(true);
//     postFormValues(values);
//   };

//   const formOne = useFormik({
//     initialValues: formikInitialValues,
//     validationSchema: Yup.object({
//       domain_name: Yup.string().required("Domain name is required"),
//       domain_url: Yup.string().matches(URL_PATTERN, "Invalid URL").required("Domain URL is required"),
//       db_name: Yup.string().required("Database name is required"),
//       ip_address: Yup.string().required("IP address is required"),
//       current_mode: Yup.string().required("Current mode is required"),
//       hosting_service_name: Yup.string().required("Hosting service name is required"),
//       dns_mapped: Yup.boolean().required("DNS mapped is required"),
//       mapped_on: Yup.string().required("Mapped on date is required")
//     }),
//     onSubmit: (values, { setSubmitting }) => {
//       console.log("onSubmit handler called", values); // Debugging log
//       handleSubmit(values, setSubmitting);
//     },
//     enableReinitialize: true,
//   });

//   return (
//     <>
//       <Popover
//         open={isEditing}
//         anchorEl={anchorEl}
//         onClose={onClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//       >
//         <form onSubmit={formOne.handleSubmit} style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
//           <TextField
//             id="domain_name"
//             name="domain_name"
//             label="Domain Name"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.domain_name}
//             helperText={formOne.touched.domain_name && formOne.errors.domain_name}
//             error={formOne.touched.domain_name && Boolean(formOne.errors.domain_name)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="domain_url"
//             name="domain_url"
//             label="Domain URL"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.domain_url}
//             helperText={formOne.touched.domain_url && formOne.errors.domain_url}
//             error={formOne.touched.domain_url && Boolean(formOne.errors.domain_url)}
//             sx={{ marginBottom: "1rem" }}
//           />
          
//           <TextField
//             id="ip_address"
//             name="ip_address"
//             label="IP Address"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.ip_address}
//             helperText={formOne.touched.ip_address && formOne.errors.ip_address}
//             error={formOne.touched.ip_address && Boolean(formOne.errors.ip_address)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="current_mode"
//             name="current_mode"
//             label="Current Mode"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.current_mode}
//             helperText={formOne.touched.current_mode && formOne.errors.current_mode}
//             error={formOne.touched.current_mode && Boolean(formOne.errors.current_mode)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="hosting_service_name"
//             name="hosting_service_name"
//             label="Hosting Service Name"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.hosting_service_name}
//             helperText={formOne.touched.hosting_service_name && formOne.errors.hosting_service_name}
//             error={formOne.touched.hosting_service_name && Boolean(formOne.errors.hosting_service_name)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <FormControlLabel
//             control={
//               <Checkbox
//                 id="dns_mapped"
//                 name="dns_mapped"
//                 checked={formOne.values.dns_mapped}
//                 onChange={formOne.handleChange}
//               />
//             }
//             label="DNS Mapped"
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="mapped_on"
//             name="mapped_on"
//             label="Mapped On"
//             type="text"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.mapped_on}
//             helperText={formOne.touched.mapped_on && formOne.errors.mapped_on}
//             error={formOne.touched.mapped_on && Boolean(formOne.errors.mapped_on)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <Button type="submit" variant="contained" color="primary" 
//             disabled={formOne.isSubmitting}>
//             Edit Domain
//           </Button>
//         </form>
//       </Popover>
//     </>
//   );
// };

// export default EditDomain;



import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, FormControlLabel, Popover, TextField } from '@mui/material';
import { addOrEditOrDeleteClient } from './clientServices';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../redux/notification/notificationActions';
import CONSTANTS from '../../../constants/constants';

// Define Yup validation schema
const validationSchema = Yup.object({
    domain_name: Yup.string()
});



const EditDomain = ({clientId,handleGetPaginatedClients,isEditing,onClose,anchorEl,domainInfo}) => {


// Define the initial values for the form
const initialValues = {
    // client_id: clientId,
    domain_name: domainInfo?.domain_name,
    domain_url: domainInfo?.domain_url,
    ip_address:domainInfo?.ip_address,
    current_mode:domainInfo?.current_mode,
    hosting_service_name: domainInfo?.hosting_service_name,
    dns_mapped:domainInfo?.dns_mapped,
    mapped_on:domainInfo?.mapped_on,
};

const dispatch = useDispatch()

const handleEditDomainApi = async (postData) =>{
    const response: any = await addOrEditOrDeleteClient("edit-domain", clientId , postData, domainInfo?.domain_id);
    if (response?.success) {
      if (handleGetPaginatedClients) {
        handleGetPaginatedClients();
      }
      dispatch(pushNotification({
        isOpen: true,
        message: response?.message,
        type: CONSTANTS.SUCCESS,
      }));
      onClose();
    } else {
      dispatch(pushNotification({
        isOpen: true,
        message: response?.detail?.message,
        type: CONSTANTS.ERROR,
      }));
    }
  }
  // Handle form submission
  const handleSubmit = (values) => {

    const editedDomainData = {
        current_mode:values.current_mode,
        domain_name:values.domain_name,
        dns_mapped:values.dns_mapped,
        domain_url:values.domain_url,
        hosting_service_name:values.hosting_service_name,
        ip_address:values.ip_address
    }
   
    handleEditDomainApi(editedDomainData)
  };

  return (
      
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
  {({ isSubmitting, values, handleChange, handleBlur, touched, errors }) => (
          <Form style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
            <TextField
              id="domain_name"
              name="domain_name"
              label="Domain Name"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.domain_name}
              helperText={touched.domain_name && errors.domain_name}
              error={touched.domain_name && Boolean(errors.domain_name)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              contentEditable={false}
              disabled
              id="domain_url"
              name="domain_url"
              label="Domain URL"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.domain_url}
              helperText={touched.domain_url && errors.domain_url}
              error={touched.domain_url && Boolean(errors.domain_url)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              id="ip_address"
              name="ip_address"
              label="IP Address"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.ip_address}
              helperText={touched.ip_address && errors.ip_address}
              error={touched.ip_address && Boolean(errors.ip_address)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              contentEditable={false}
              disabled
              id="current_mode"
              name="current_mode"
              label="Current Mode"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.current_mode === 'Off'?'Transparent Mode':'Block Mode'}  // Example value binding
              helperText={touched.current_mode && errors.current_mode}
              error={touched.current_mode && Boolean(errors.current_mode)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              id="hosting_service_name"
              name="hosting_service_name"
              label="Hosting Service Name"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.hosting_service_name}
              helperText={touched.hosting_service_name && errors.hosting_service_name}
              error={touched.hosting_service_name && Boolean(errors.hosting_service_name)}
              sx={{ marginBottom: "1rem" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="dns_mapped"
                  name="dns_mapped"
                  checked={values.dns_mapped}
                  onChange={handleChange}
                />
              }
              label="DNS Mapped"
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              contentEditable={false}
              disabled
              id="mapped_on"
              name="mapped_on"
              label="Mapped On"
              type="text"
              size="small"
            //   onBlur={handleBlur}
            //   onChange={handleChange}
              value={values.mapped_on}
            //   helperText={touched.mapped_on && errors.mapped_on}
            //   error={touched.mapped_on && Boolean(errors.mapped_on)}
              sx={{ marginBottom: "1rem" }}
            />
            <Button type="submit" variant="contained" sx={{color:'#FFFFFF'}} disabled={isSubmitting}>
              Edit Domain
            </Button>
          </Form>
        )}
    </Formik>
    </Popover>
  );
};

export default EditDomain;
