import { USER_PREFERENCES, USER_PROFILE_DATA } from "../../constants/actions";


const initialUserProfileState = {
    userProfileData: {},
    userPreferences:{}

};

  


export const profileReducer = (state = initialUserProfileState, action) => {
    switch (action.type) {
     
  
      case USER_PROFILE_DATA:
        return { 
            ...state, 
            userProfileData: action.payload 
        };

      case USER_PREFERENCES:
        return { 
            ...state, 
            userPreferences: action.payload 
        };
  
     
      default:
        return state;
    }
  };
  

