// DataCards.js
import React from 'react';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import DonutChart from './DonutPercetange';
import { makeStyles } from '@material-ui/core';
import NoAttacksFound from '../../NoAttacksFound';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'space-around',
    alignItems: 'center',
    width:"98%",
    [theme.breakpoints.up('xl')]: {
      height: '85%',
    },
  },
  severityBox: {
    width:'93%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(5),
    },
  },
  severityName: {
    color: '#737373 !important',
    fontSize: '1.1rem !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
        fontSize: '1.6rem !important',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem !important',
      },
  },
  gridItem: {
    paddingRight: theme.spacing(8),
   
  },
}));

const AttacksBySeverity = ({id,totalCount,attacksBySeverity}) => {

  const classes = useStyles()
  // const hasTotalCount = attacksBySeverity?.some(item => item?.value > 0);
  if(!totalCount){
    return <NoAttacksFound id="noAttacksBySeverityFound"/>
  }
  return (
      <Box className={classes.root}>
        {attacksBySeverity?.map((item, index) => (
        <Box id={`${id}-severity`} key={index} className={classes.severityBox} >
          <Grid container alignItems="center">
            <Grid className={classes.gridItem} item xs={5} md={4} xl={7}>
              <Typography  fontWeight={530} className={classes.severityName} id={`${id}-severityName`} variant="h2">{item?.name}</Typography>
            </Grid>
            <Grid pl={2} item xs={5} md={7} xl={5}>
              <DonutChart id={`${id}-severityDonut`}  percentage={item?.percentage} severity={item?.name}/>
            </Grid>
          </Grid>
        </Box>
      ))}</Box>
  );
};

export default AttacksBySeverity;


