import {
  TEMPLATE_UPLOAD,
  TEMPLATE_DOWNLOAD,
  TEMPLATE_UPLOAD_ENDPOINT,
  TEMPLATE_DOWNLOAD_ENDPOINNT,
  EULA_TEXT_DOWNLOAD_ENDPOINT,
  USER_PREFERENCE
} from "../constants/endpoints";
import { ResponseProps } from "./request";
import { request } from "./request";
import { MetaInfo } from "../types/types";
import CONSTANTS from "../constants/constants";


export const uploadFileApi = async (
  fileName: string,
  file: File,
  documentPath: string,
  meta?: MetaInfo
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${fileName}`);
  const params = {
    documentName: fileName,
    file: fileOfBlob,
    documentPath: documentPath,
    documentDescription: "",
    metaInfo: JSON.stringify(meta),
  };


  const r: ResponseProps = (await request.postForm(
    `${TEMPLATE_UPLOAD_ENDPOINT}`,
    params
  )) as ResponseProps;
  if (r && r.success) {
    return { success: true, data: r.data, message: r.message };
  }
  return { success: false, data: {}, message: "" };
};


export const downloadFile = async (id: string, fileName: string) => {
  let Token = sessionStorage.getItem(CONSTANTS.REACT_TOKEN);
  const downloadFileUrl = `${sessionStorage.getItem(CONSTANTS.AWGMENT_API)}${EULA_TEXT_DOWNLOAD_ENDPOINT}`
  fetch(
    downloadFileUrl,
    {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    }
  )
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    });
};

export const downloadTemplateApi = async (
  id: string
): Promise<{ success: boolean; message?: string; blobFile?: any }> => {
  const response = (await request.getBlob(
    `${TEMPLATE_DOWNLOAD_ENDPOINNT}/${id}`
  )) as ResponseProps;

  if (response && response.success) {
    return { success: true, blobFile: response.data };
  }

  return { success: false };
};
// export default {};

export const deleteDocument = async (id) => {
  const deleteFileUrl = `${sessionStorage.getItem(CONSTANTS.AWGMENT_API)}${EULA_TEXT_DOWNLOAD_ENDPOINT}`
  const r: ResponseProps = (await request.delete(deleteFileUrl)) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false };
};


export const fetchFileText = async (id: string) => {
  let Token = sessionStorage.getItem(CONSTANTS.REACT_TOKEN);
  
  const fetchEulaTextUrl = `${sessionStorage.getItem(CONSTANTS.AWGMENT_API)}${EULA_TEXT_DOWNLOAD_ENDPOINT}${sessionStorage.getItem(CONSTANTS.EULA_DOC_ID)}`

  // console.log("fetchEulaTextUrl",fetchEulaTextUrl)
  try {
    const response = await fetch(
      fetchEulaTextUrl
     ,
      {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${Token}`,
        }),
      }
    );
    const blobData = await response.blob();
    return blobData.text();
  } catch (error) {
    console.error("Failed to fetch EULA text:", error);
    throw error;
  }
};



export const uploadProfileImage = async (
  file: string,
  name: string
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${name}`);
  const params = {
    file: fileOfBlob,
  };
  const r: ResponseProps = (await request.postForm(
    `${sessionStorage.getItem(
      CONSTANTS.AWGMENT_API
    )}${USER_PREFERENCE}${"/profile-picture"}`,
    params
  )) as ResponseProps;
  if (r) {
    // console.log("profile response ",r)
    // const data: any = r.data as any;
    return {

      success: r.success ? true : false,
      message: r.message,
      data: r.data,
    };
  }
  return { success: false };
};
 
