
import {Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CONSTANTS from '../../../constants/constants';
import { makeStyles } from '@material-ui/core';
import { addOrEditOrDeleteClient } from './clientServices';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import '../popupstyles.css'
import { CustomButton, PopUpHeading } from '../reuseStyles';
import { pushNotification } from '../../../redux/notification/notificationActions';
import moment from 'moment';

export const useStyles = makeStyles((theme) => ({
  
    fieldLabel: {
      marginBottom: "10px",
    },
    screenHeadingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#4B6D8F",
      color: "#FFFFFF",
      paddingLeft:"0.8rem",

      [theme.breakpoints.up('xl')]: {
        padding:"1rem",
      },

    },
  }));

  
const CreateClient = ({setShow,handleGetAllClients}) =>{


    const dispatch = useDispatch()
    const [initalStateValues, setInitalStateValues] = useState({
        client_name:"",
        business_owner:"",
        business_owner_email:"",
        business_owner_phone:"",
        technical_owner:"",
        technical_owner_email:"",
        technical_owner_phone:"",
        onboarding_date: moment().format('DD/MM/YYYY'),
        address:"",
        gstin:"",
        pan:""

      });
    
    const formPayload = (values: any) => ({
        client_name: values?.client_name,
        business_owner:values?.business_owner,
        business_owner_email:values?.business_owner_email,
        business_owner_phone:values?.business_owner_phone,
        technical_owner:values?.technical_owner,
        technical_owner_email:values?.technical_owner_email,
        technical_owner_phone:values?.technical_owner_phone,
        onboarding_date: values?.onboarding_date,
        address:values?.address,
        gstin:values?.gstin,
        pan:values?.pan
        
      });

    const postFormValues = (values: any) => {
        const postData = formPayload(values);
        handlePostApiRequest(postData);
      };
    
    const handlePostApiRequest = async (data) => {
      console.log("data in create api ",data)
      const response = await addOrEditOrDeleteClient("add-client", null, data);
      
      if (response?.success) {
        setShow(false);
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.SUCCESS,
            })
          );
          handleGetAllClients();
        } else {
          setShow(false);
          dispatch(
            pushNotification({
              isOpen: true,
              message: response?.error,
              type: CONSTANTS.ERROR,
            })
          );
        }
      };

      const handleSubmit = async (values: any, setSubmitting: any) => {
        setSubmitting(true);
        postFormValues(values);
      };

  
    const formOne = useFormik({
        initialValues: initalStateValues,
        validationSchema: Yup.object({
         client_name: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Client name must contain only letters and numbers')
            .required("Client Name is Requried")
            // .max(10, "Maximum length is 10 Characters")
            // .min(4, "Minimum length of is 4 Characters")
            .trim(),
          business_owner: Yup.string()
            .matches(/^(?=.*[A-Za-z])[\w ]+$/, 'Business Owner must contain only letters')
            .required("Business owner's name is Requried"),
          business_owner_email:Yup.string()
            .email("Please Enter a Valid Email Id")
            .required("Business owner's email is Requried"),
          business_owner_phone:Yup.string()
            .notRequired() // Makes the field optional
            .matches(/^\d{0,10}$/, 'Phone number must be at most 10 digits')
            // .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')s,
            .max(10, "Maximum length of phone number is 10 digits"),
          technical_owner: Yup.string()
            .matches(/^(?=.*[A-Za-z])[\w ]+$/,'Technical Owner must contain only letters')
            .required("Technical owner's name is Requried"),
          technical_owner_email:Yup.string()
            .email("Please Enter a Valid Email Id")
            .required("Technical owner's email is Requried"),
          technical_owner_phone:Yup.string()
            .notRequired() // Makes the field optional
            .matches(/^\d{0,10}$/, 'Phone number must be at most 10 digits')
            .max(10, "Maximum length of phone number is 10 digits"),
            // .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
          address:Yup.string(),
          gstin:Yup.string(),
          pan:Yup.string(),


        }),
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          },
          enableReinitialize: true,
      });
  

    const classes = useStyles()

    return (
      <Box 
        padding={"2%"}>
        <form onSubmit={formOne.handleSubmit}>
          <Box
            sx={{
              position:"absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor:"#FFFFFF",
              overflow:"auto",
              // zIndex:1,
              "@media (max-width:600px)":{
                width:"18rem",height:"20rem"
              },
              "@media (min-width:601px)":{
                width:"24rem",height:"22rem"
              },
              "@media (min-width:768px)":{
                width:"30rem",height:"25rem"
              },
              "@media (min-width:1024px)":{
                width:"38rem",height:"26rem"
              },
              "@media (min-width:1366px)":{
                width:"44rem",height:"28rem"
              },
              "@media (min-width:2560px)":{
                width:"65rem",height:"30rem"
              },
              "@media (min-width:3200px)":{
                width:"65rem",height:"30rem"
              },}}>
        <Box className={classes.screenHeadingContainer}>
          <PopUpHeading variant='h4'>Register Client</PopUpHeading>
            <Box>
              <Button
                sx={{ color: "#FFFFFF" }}
                onClick={() => {
                  setShow(false);
                }}
              >
                <CloseOutlinedIcon />
              </Button>
            </Box>
        </Box>
            <Box
                display={"flex"}
                flexDirection={"column"}
                mt={"2%"}
                gap={"1rem"}
                p={"0.8rem"}
              
            >
            <Box display={"flex"} flexDirection={"column"}>
                <label className={classes.fieldLabel}>Client Name <span style={{color:"red"}}>*</span></label>
                <TextField
                  id="client-name"
                  name="client_name"
                  size="small"
                  placeholder='Enter Client Name'
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.client_name}
                  helperText={
                    formOne.touched.client_name && formOne.errors.client_name
                  }
                  error={
                    formOne.touched.client_name && Boolean(formOne.errors.client_name)
                  }
                  sx={{ 
                    width: "36rem",
                 
                  }}
                  
                />
            </Box>
              <Box display={"flex"} flexDirection={"column"}>
              <label className={classes.fieldLabel}>Business Owner <span style={{color:"red"}}>*</span></label>

                <TextField
                  id="business-owner"
                  name="business_owner"
                  size="small"
                  placeholder='Enter Business Owner'
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.business_owner}
                  helperText={
                    formOne.touched.business_owner && formOne.errors.business_owner
                  }
                  error={
                    formOne.touched.business_owner && Boolean(formOne.errors.business_owner)
                  }
                  sx={{ width: "36rem" }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <label className={classes.fieldLabel}>BO Email <span style={{color:"red"}}>*</span></label>
                <TextField
                  id="bo-email"
                  name="business_owner_email"
                  size="small"
                  placeholder="Enter Business Owner's email"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.business_owner_email}
                  helperText={formOne.touched.business_owner_email && formOne.errors.business_owner_email}
                  error={
                    formOne.touched.business_owner_email && Boolean(formOne.errors.business_owner_email)
                  }
                  sx={{width: "36rem" }}
                />
              </Box>
             
             
              <Box display={"flex"} flexDirection={"column"}>
                <label className={classes.fieldLabel}>BO Phone </label>
                <TextField
                  id="bo-phone"
                  name="business_owner_phone"
                  size="small"
                  placeholder="Enter Business Owner's Phone Number"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.business_owner_phone}
                  helperText={
                    formOne.touched.business_owner_phone && formOne.errors.business_owner_phone
                  }
                  error={
                    formOne.touched.business_owner_phone &&
                    Boolean(formOne.errors.business_owner_phone)
                  }
                  sx={{ width: "36rem" }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
              <label className={classes.fieldLabel}>Technical Owner <span style={{color:"red"}}>*</span></label>

              <TextField
                id="technical-owner"
                name="technical_owner"
                size="small"
                placeholder="Enter Technical Owner's name"
                onBlur={formOne.handleBlur}
                onChange={formOne.handleChange}
                value={formOne.values.technical_owner}
                helperText={
                  formOne.touched.technical_owner && formOne.errors.technical_owner
                }
                error={
                  formOne.touched.technical_owner && Boolean(formOne.errors.technical_owner)
                }
                sx={{width: "36rem"  }}
              />
              </Box>
              
            <Box display={"flex"} flexDirection={"column"}>
            <label className={classes.fieldLabel}>TO Email <span style={{color:"red"}}>*</span></label>
            <TextField
              id="to-email"
              name="technical_owner_email"
              size="small"
              placeholder="Enter Technical Owner's email"
              onBlur={formOne.handleBlur}
              onChange={formOne.handleChange}
              value={formOne.values.technical_owner_email}
              helperText={formOne.touched.technical_owner_email && formOne.errors.technical_owner_email}
              error={
                formOne.touched.technical_owner_email && Boolean(formOne.errors.technical_owner_email)
              }
              sx={{ width: "36rem" }}
            />
            </Box>

            <Box display={"flex"} flexDirection={"column"}>
            <label className={classes.fieldLabel}>TO Phone</label>
            <TextField
              id="to-phone"
              name="technical_owner_phone"
              size="small"
              placeholder="Enter Technical Owner's Phone Number"
              onBlur={formOne.handleBlur}
              onChange={formOne.handleChange}
              value={formOne.values.technical_owner_phone}
              helperText={
                formOne.touched.technical_owner_phone && formOne.errors.technical_owner_phone
              }
              error={
                formOne.touched.technical_owner_phone &&
                Boolean(formOne.errors.technical_owner_phone)
              }
              sx={{ width: "36rem" }}
            />
            </Box>

            <Box display={"flex"} flexDirection={"column"}>
              <label className={classes.fieldLabel}>Address</label>

              <TextField
                id="address"
                name="address"
                size="small"
                placeholder="Enter Client's address"
                onBlur={formOne.handleBlur}
                onChange={formOne.handleChange}
                value={formOne.values.address}
                helperText={
                  formOne.touched.address && formOne.errors.address
                }
                error={
                  formOne.touched.address && Boolean(formOne.errors.address)
                }
                sx={{width: "36rem"  }}
              />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
              <label className={classes.fieldLabel}>GSTIN</label>

              <TextField
                id="GSTIN"
                name="gstin"
                size="small"
                placeholder="Enter GSTIN"
                onBlur={formOne.handleBlur}
                onChange={formOne.handleChange}
                value={formOne.values.gstin}
                helperText={
                  formOne.touched.gstin && formOne.errors.gstin
                }
                error={
                  formOne.touched.gstin && Boolean(formOne.errors.gstin)
                }
                sx={{width: "36rem"  }}
              />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
              <label className={classes.fieldLabel}>PAN</label>

              <TextField
                id="PAN"
                name="pan"
                size="small"
                placeholder="Enter PAN"
                onBlur={formOne.handleBlur}
                onChange={formOne.handleChange}
                value={formOne.values.pan}
                helperText={
                  formOne.touched.pan && formOne.errors.pan
                }
                error={
                  formOne.touched.pan && Boolean(formOne.errors.pan)
                }
                sx={{width: "36rem"  }}
              />
              </Box>
                     
            </Box>
        
            <CustomButton 
              sx={{
              mb:1,
              alignSelf:"center",
              ml:"2%",
              textTransform:"none"}}
              variant={"contained"} 
              type="submit"
              disabled={formOne.isSubmitting}>
              Register</CustomButton>
              </Box>
          
        </form>
          
      </Box>
    )

}

export default CreateClient