import { LOADER,IS_LOADING_DATA, LINE_CHART_LOADER,IS_LOADING_ATTACKS_BLOCKED,
   REFRESH_PAGE, HITS_AND_ATTACKS_LOADER, GEO_LOCATION_LOADER, ATTACKS_BY_SEVERITY_LOADER,
    ATTACKS_BY_CATEGORY_LOADER, ATTACK_TRENDLINE_LOADER, ATTACKS_INFORMATION_LOADER, 
    USER_REQUEST_STATS_LOADER, DOWNLOAD_PDF, SET_COMBINED_LOADER } from "../../constants/actions";


const initialLoadingState = {

    isLoading: false,
    loadingData:{
      table:false,chart:false
    },
    isLoadingLineChart:false,
    isLoadindAttacksBlockedPopup:false,
    isRefresh:false,
    hitsAndAttacksLoading: false,
    geoLocationLoading: false,
    attacksBySeverityLoading: false,
    attacksByCategoryLoading: false,
    attackTrendlineLoading: false,
    attacksInformationLoading: false,
    userRequestStatsLoading: false,
    isDownloadingPdf: false,
    combinedLoader:false
    
  };
  



export const loadingActionReducer = (state = initialLoadingState, action) => {
  switch (action.type) {
    case LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case REFRESH_PAGE:
      return {
          ...state,
          isRefresh: !state.isRefresh, // Toggle the current isRefresh state
        };
    case HITS_AND_ATTACKS_LOADER:
      return { ...state, hitsAndAttacksLoading: action.payload };
    case GEO_LOCATION_LOADER:
      return { ...state, geoLocationLoading: action.payload };
    case ATTACKS_BY_SEVERITY_LOADER:
      return { ...state, attacksBySeverityLoading: action.payload };
    case ATTACKS_BY_CATEGORY_LOADER:
      return { ...state, attacksByCategoryLoading: action.payload };
    case ATTACK_TRENDLINE_LOADER:
      return { ...state, attackTrendlineLoading: action.payload };
    case ATTACKS_INFORMATION_LOADER:
      return { ...state, attacksInformationLoading: action.payload };
    case USER_REQUEST_STATS_LOADER:
      return { ...state, userRequestStatsLoading: action.payload };
    case DOWNLOAD_PDF:
      return { ...state, isDownloadingPdf: action.payload };
    case SET_COMBINED_LOADER:
      return { ...state, combinedLoader: action.payload };
  
      
    default:
      return state;
  }
};
