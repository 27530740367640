import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Typography, Badge, Avatar } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

// Import your custom SVG icons
import { ReactComponent as DashboardIcon } from '../../../assets/icons/svg/dashboardIcon.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/svg/user.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/svg/profileIcon.svg';
import { ReactComponent as ExitToAppIcon } from '../../../assets/icons/svg/logoutIcon.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/svg/userIcon.svg';
import { ReactComponent as SupportIcon } from '../../../assets/icons/svg/supportIcon.svg';
import { ReactComponent as DashboardBoldIcon } from '../../../assets/icons/svg/dashboardIconBold.svg';

import './Sidebar.css';
import CONSTANTS from '../../../constants/constants';
import IconBadge from '../../common/iconBadge';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useLogout } from '../../../screen/utils/logout';
import { getUserProfileImage, userProfilePageApi } from '../../../services/profileApi';
import { StyledAvatar } from '../../../screen/profile/UserProfilePage';

const drawerWidth = 200;
const collapsedWidth = '4.2rem';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    border: '2px solid blue',
    width: '100%',
    height: 'calc(100% - 70px) !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  listItem: {
    '&:hover': {
      width:drawerWidth-20,
      backgroundColor: '#CCDAFF !important',
      borderRadius: '0.5rem !important',
      color:'#FFFFFF',
    },
  
    '&.Mui-selected': {
      color:'#FFFFFF',
      width:drawerWidth-20,
      marginLeft:8,
      backgroundColor: '#0046FF !important',
      borderRadius: '0.5rem !important',
      '&:hover': {
        backgroundColor: '#0038CC !important',
      },
    },
  },
  listItemText:{
    paddingLeft:8,    
  },
  listItemCollapsed: {
    '&.Mui-selected': {
      backgroundColor: '#0046FF !important',
      marginLeft:10,
      paddingLeft:8,
      borderRadius:'0.5rem',
     
    },
  },
  avatar: {
    width: '2rem !important',
    height: '2rem !important',
    // objectFit: "contain"
    objectFit: "cover",
    borderRadius:50,
    display: 'block',
    '@media (min-width:2560px)': {
      width: '2.4rem !important',
      height: '2.4rem !important',
    },
    
  },


 
}));

interface SidebarProps {
  id:string;
  open:boolean;
  handleMouseLeave:any;
  handleMouseEnter:any; 
  isMobile:any;
}


const Sidebar:React.FC<SidebarProps> = ({ id,open,handleMouseLeave,handleMouseEnter, isMobile }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const userType = sessionStorage.getItem(CONSTANTS.USER_TYPE) === 'wafAdmin'


 const dispatch = useDispatch()
  const {isWafAdmin} = useSelector((state:any)=>state.authentication)
  const {userPreferences,userProfileData} = useSelector((state:any)=>state.profile)
  // console.log("userPreferences ",userPreferences)
  const {domain} = useSelector((state: any) => state.domainData);

  const isDomainsAvailable = domain?.domain_id?false:true

  const isDashboardVisible = userType?( isDomainsAvailable && userType):false
  const handleNavigation = (path) => {
    navigate(path);
  };

  
  const listItems = [
    { text: 'Dashboard', icon: <DashboardIcon/>, 
        hasAccess:true,class:'sidebar_dashboard-icon', disabled:isDashboardVisible, boldIcon: <DashboardBoldIcon />, path: CONSTANTS.NAVIGATE_TO_DASHBOARD },
    { text: 'Clients', icon: <PeopleIcon />, hasAccess:isWafAdmin,path: `${CONSTANTS.NAVIGATE_TO_DASHBOARD}clients` },
    { text: 'Users', icon: <PeopleIcon />,  hasAccess:isWafAdmin,path: `${CONSTANTS.NAVIGATE_TO_DASHBOARD}users` },
    // { text: 'Support', icon: <IconBadge icon={<SupportIcon />} badgeContent={4}  />,
    //   path: `${process.env.PUBLIC_URL}/support`,disabled:true,hasAccess:true },
  ];

  const commonMenus = [
    // { text: 'Profile', icon: <IconBadge icon={<ProfileIcon />} badgeContent={4} />,class:'sidebar_menu-icon', path: `${process.env.PUBLIC_URL}/user-profile` },
    { text: 'Logout', icon: <ExitToAppIcon />,class:'logout-icon', path: '/logout' },
  ];

  const username = sessionStorage.getItem(CONSTANTS.FIRST_NAME);
  const company = userProfileData?.data?.client_details?.client_name

  const handleLogout = useLogout()

  useEffect(()=>{


    switch (window.location.pathname) {
      case `${CONSTANTS.NAVIGATE_TO_DASHBOARD}clients`:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}clients`)
        break;

      case `${CONSTANTS.NAVIGATE_TO_DASHBOARD}users`:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}users`)
        break;
      case `${CONSTANTS.NAVIGATE_TO_DASHBOARD}user-profile`:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}user-profile`)
        break;
      case CONSTANTS.NAVIGATE_TO_DASHBOARD:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`)
        break;
  
      default:
        isDashboardVisible?navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}clients`):navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`)
        break;
    }
  },[])

  useEffect(()=>{
    getUserProfileImage(dispatch)
    userProfilePageApi(dispatch);
  },[])

  
  return (

    <Drawer
      id={id}
      variant={'permanent'}
      open={true}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: open ? {xs:'12rem',md:'14rem',lg:'16rem',xl:'17rem'} : {xs:'4rem',lg:'4.2rem',xl:'4.2rem'},
          transition: 'width 0.4s',
          overflowX: 'hidden',border:'none',
          marginTop: { xs: 5, sm: 7, md: 7.5 },
        },
      }}
      onClose={handleMouseLeave}
      ModalProps={{
        keepMounted: true,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box id={`${id}-menuContainer`} className='sidebar_menu-container'>
        <List>
          <Box sx={{display:'flex',alignItems:'center'}} id={`${id}-profileIcon`} onClick={()=>handleNavigation(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}user-profile`)}  className='sidebar_menu-profile-icon'>
            
            {userPreferences?.profilePicture?
            <img alt="User Avatar" src={`data:image/png;base64,${userPreferences.profilePicture}`} 
              className={classes.avatar}/>:
              <ListItemIcon className='sidebar_icon'><UserIcon/></ListItemIcon>}
            <Box sx={{maxWidth:150,pl: 3 }}>
            
                <Typography fontWeight={600}
                  sx={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    // hyphens: 'auto',
                  }}
                id={`${id}-username`} variant="body2">{username}</Typography>
                <Typography sx={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    // hyphens: 'auto',
                  }} textTransform={"capitalize"} id={`${id}-companyName`}  variant="body2" color="textSecondary">{company}</Typography>
            </Box>
            
          </Box>

          {listItems?.filter(menuItem=>menuItem.hasAccess)?.map((item, index) => {
            // console.log("location?.pathname",location?.pathname,"item.path",item.path)
            return (
            <ListItem
              id={`${id}-menuItem-${index}`} 
              disableRipple
              disableTouchRipple
              button
              key={index}
              onClick={() => handleNavigation(item.path)}
              selected={location?.pathname === item.path}
              disabled={item.disabled}
              className={open ? classes.listItem : classes.listItemCollapsed}
              sx={{width:{xs:44,xl:44},height:{xs:44,xl:44},mt:2,mb:2,ml:open?1:0.5}}
            >
              <ListItemIcon 
                id={`${id}-menuIcon-${index}`} 
                className={location?.pathname === item?.path ?`sidebar_icon sidebar_menu-icon ${item?.class}`:'sidebar_icon'}
                >
                {location?.pathname === item?.path ? item?.icon : item?.boldIcon || item?.icon}
              </ListItemIcon>
              {open && <ListItemText id={`${id}-menuText-${index}`} className={classes.listItemText} primary={item?.text} />}
            </ListItem>
          )})}
        </List>
        <List>
          {commonMenus?.map((item, index) => (
            <ListItem
              id={`${id}-commonMenuItem-${index}`}
              disableRipple
              button
              key={index}
              onClick={item?.text === 'Logout'?handleLogout:()=>handleNavigation(item?.path)}
              selected={location.pathname === item?.path}
              className={open ? classes.listItem : classes.listItemCollapsed}
              sx={{width:40,height:40,mt:1,mb:1,ml:open?1:0.5}}

            >
              <ListItemIcon id={`${id}-commonMenuItemIcon-${index}`} className={location.pathname === item.path?`logout-icon-svg ${item.class}`:'logout-icon-svg'}>{item.icon}</ListItemIcon>
              {open && <ListItemText id={`${id}-commonMenuItemText-${index}`} className={classes.listItemText} primary={item.text} />}
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;

