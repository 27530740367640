import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18nextInit';
import CONSTANTS from './constants/constants';
import { useDispatch } from 'react-redux';
import { setIsRefresh } from './redux/loader/loadingActions';

if(sessionStorage.getItem(CONSTANTS.REACT_TOKEN)){


    let appConfig = {
        realm :sessionStorage.getItem(CONSTANTS.KC_REALM_NAME) || process.env.REACT_APP_REALM,
        authURL : sessionStorage.getItem(CONSTANTS.KC_AUTH_URL) || process.env.REACT_APP_AUTH_URL,
        clientId : sessionStorage.getItem(CONSTANTS.KC_CLIENT_ID) || process.env.REACT_APP_CLIENTID,
        gatewayURL : sessionStorage.getItem(CONSTANTS.GATEWAY_URL) || process.env.REACT_APP_GATEWAY_URL,
        awgmentApi:sessionStorage.getItem(CONSTANTS.AWGMENT_API) || process.env.REACT_APP_AWGMENT_URL,
        eulaDocId:sessionStorage.getItem(CONSTANTS.EULA_DOC_ID) || process.env.REACT_APP_EULA_DOC_ID,
        dmsDocId:sessionStorage.getItem(CONSTANTS.DMS_DOC_ID),

    };
  
    ReactDOM.render(
        <React.StrictMode>
            <Router>
                <App config={appConfig}/>
               
            </Router>
        </React.StrictMode>,
        document.getElementById('root')
        
    );
    
 }else{

    // fetch(`https://apps-dev.techsophy.com/cyberwasp/cybersophy.json`)
    // .then(async (response) => {

    //     return response.json();
        
    // })
    // .then((config)=>{


    //     ReactDOM.render(
    //     <React.StrictMode>
    //         <Router>
    //             <App config={config}/>
    //         </Router>
    //     </React.StrictMode>,
    //     document.getElementById('root')
    //     );
    // })
    // .catch((error)=>{

    //     let config = {
    //         realm :process.env.REACT_APP_REALM,
    //         authURL : process.env.REACT_APP_AUTH_URL,
    //         clientId : process.env.REACT_APP_CLIENTID,
    //         gatewayURL : process.env.REACT_APP_GATEWAY_URL,
    //         awgmentApi:process.env.REACT_APP_AWGMENT_URL,
    //         eulaDocId:process.env.REACT_APP_EULA_DOC_ID,
    //         dmsDocId:"",
    //     }

    //     ReactDOM.render(
    //         <React.StrictMode>
    //             <Router>
    //                 <App config={config}/>
    //             </Router>
    //         </React.StrictMode>,
    //         document.getElementById('root')
    //         );
    // })


    fetch(`${window.location.origin}${window.location.pathname}.json`)
    .then(async (response) => {
    
        return response.json();

    })
    .then((config)=>{

        ReactDOM.render(
        <React.StrictMode>
            <Router>
                <App config={config}/>
            </Router>
        </React.StrictMode>,
        document.getElementById('root')
        );
    })
    .catch((error)=>{
         let config = {
                realm :process.env.REACT_APP_REALM,
                authURL : process.env.REACT_APP_AUTH_URL,
                clientId : process.env.REACT_APP_CLIENTID,
                gatewayURL : process.env.REACT_APP_GATEWAY_URL,
                awgmentApi:process.env.REACT_APP_AWGMENT_URL,
                eulaDocId:process.env.REACT_APP_EULA_DOC_ID,
                dmsDocId:"",
            }

            ReactDOM.render(
                <React.StrictMode>
                    <Router>
                        <App config={config}/>
                    </Router>
                </React.StrictMode>,
                document.getElementById('root')
                );
    })

}