import React, { useState } from 'react';
import { Popover, Typography, TextField, Button, Divider, styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, differenceInCalendarDays, differenceInHours, addDays, isBefore, isAfter  } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { request } from '../../../services/request';
import { getDownloadPDFApiEndpoint } from '../../../constants/endpoints';
import CONSTANTS, { DATE_TIME_FORMAT } from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../redux/notification/notificationActions';
import FileSaver from 'file-saver';
import './customDate.css'
import { setIsDownloadingPdf } from '../../../redux/loader/loadingActions';
import { PickersDay } from '@mui/x-date-pickers';
import './customDate.css'
import { convertToISTFormat, convertToUTCFormat } from '../../../screen/utils/convertTime';
const useStyles = makeStyles((theme) => ({
  popover: {
    padding: '1rem',
    width: '30%',
    '@media (min-width:2560px)': {
      width: '24%',
    },
   
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  datePickerLabel: {
    color: '#0046FF !important',
    marginBottom: '0.5rem',
    fontSize:'0.9rem !important',
    '@media (min-width:2560px)': {
      fontSize:'1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize:'1.6rem !important',
    },
  },

  datePicker: {
    '& .MuiInputBase-root': {
      width:'80%',
      // paddingRight: '1.2rem',

      border: 'none',
      '&:hover': {
        border: 'none',
      },
      '&.Mui-focused': {
        border: 'none',
        boxShadow: 'none',
      },
      '&::before': {
        borderBottom: 'none',
      },
      '&::after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
    '& .MuiInputBase-input': {
      padding: '0',
      color: 'gray',
      fontSize: '0.9rem !important',
        '@media (min-width:2560px)': {
          fontSize:'1.2rem !important',
        },
        '@media (min-width:3860px)': {
          fontSize:'1.6rem !important',
        },
    },
   

    '& .MuiInputBase-input::placeholder': {
      textTransform: 'uppercase', // Add this line
    },
    
  },
  divider: {
    margin: '0 -1rem 0.1rem -1rem !important',
    backgroundColor:'#0046FF'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    textTransform: 'uppercase',
    fontSize:'0.9rem !important',
    '@media (min-width:2560px)': {
      fontSize:'1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize:'1.6rem !important',
    },
  },
  cancelButton: {
    color: '#909097 !important',
  },
  okButton: {
    color: '#0046FF !important',
  },
  selectedDay: {
    backgroundColor: '#0046FF !important',
    color: '#fff !important',
    
  },
}));


export const datePickerStyles = {  
  
  '& .PrivatePickersYear-yearButton': {
    fontSize: '0.9rem',
    color: '#0046FF',
  },
  '& .PrivatePickersYear-yearButton.Mui-selected': {
    fontSize: '0.9rem',
    color: '#FFFFFF',
    backgroundColor: '#0046FF !important',
  },
  '& .PrivatePickersMonth-root': {
    fontSize: '0.9rem',
    color:'#0046FF'
  },
  '& .PrivatePickersMonth-root.Mui-selected': {
    fontSize: '0.9rem',
    color: '#FFFFFF',
    backgroundColor: '#0046FF !important',
  },
  '& .MuiPickersDay-root': {
    // color: '#0046FF !important',
  },
  '& .MuiPickersDay-root.Mui-selected': {
    backgroundColor: '#0046FF !important',
    color: '#FFFFFF !important',
  },
 
 
};
 
const CustomDate = ({
  id,
  anchorEl,
  setAnchorEl,domainId
}) => {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const customDateId = open ? id : undefined;

  const dispatch = useDispatch()



  const formatDate = (date) => {
    return format(date, DATE_TIME_FORMAT);
  };

  const handleOkClick = async () => {


    
    if (startDate && endDate) {
      setAnchorEl(null);

      const endDateTime = new Date(endDate)

      // Set the hours, minutes, and seconds to 23:59:00
      endDateTime?.setHours(23);
      endDateTime?.setMinutes(59);
      endDateTime?.setSeconds(59);

      dispatch(setIsDownloadingPdf(true))
      const daysDifference = differenceInCalendarDays(new Date(endDateTime), new Date(startDate));
      const hoursDifference = differenceInHours(new Date(endDateTime), new Date(startDate));

  
      if (daysDifference > 8) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: "You can download logs for less than or equal to 7 days",
            type: CONSTANTS.ERROR,
          })
        );
      } else if (daysDifference <= 0 && hoursDifference <=0) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: "Start date should be prior to end date",
            type: CONSTANTS.ERROR,
          })
        );
      } else if(hoursDifference > 1 && daysDifference <= 7) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: "Please wait...",
            type: CONSTANTS.SUCCESS,
          })
        );

        // console.log("startDate ",startDate)

        // console.log("endDateTime ",endDateTime)

        const formattedStartDate = convertToISTFormat(startDate);
        const formattedEndDate = convertToISTFormat(endDateTime);

        const startAndEndDateTime = {
          from: formattedStartDate,
          to: formattedEndDate,
        };
        
        const DOWNLOAD_PDF_ENDPOINT = getDownloadPDFApiEndpoint(domainId, startAndEndDateTime);

        try {
          const res:any = await request.getBlob(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${DOWNLOAD_PDF_ENDPOINT}`);

          if(!res?.success) {
            dispatch(
              pushNotification({
                isOpen: true,
                message: "Failed to download the logs or No data found for the given period of time",
                type: CONSTANTS.ERROR,
              })
            );
          }
          else if(res?.data){
            console.log("res data ",res?.data,res)
            const blob = new Blob([res?.data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, CONSTANTS.DOWNLOADED_LOGS_FILE_NAME);
            
            dispatch(
              pushNotification({
                isOpen: true,
                message: "Downloaded the logs successfully",
                type: CONSTANTS.SUCCESS,
              })
            );
          }
          
        } catch (error) {
          dispatch(
            pushNotification({
              isOpen: true,
              message: "Unable to download the logs or No data found for the given period of time",
              type: CONSTANTS.ERROR,
            })
          );
        }
      }
      dispatch(setIsDownloadingPdf(false))
    }
  };


  const disableEndDate = (date) => {
    if (!startDate) return false;
    const maxEndDate = addDays(new Date(startDate), 6);
    return isBefore(date, new Date(startDate)) || isAfter(date, maxEndDate);
  };

  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Popover
        id={customDateId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <div id={`${customDateId}-container`} className={classes.datePickerContainer}>
          <div id={`${customDateId}-startDateContainer`}>
            <Typography id={`${customDateId}-startDateLabel`} variant="body2" className={classes.datePickerLabel}>
              Start Date
            </Typography>
            <DatePicker
              className='custom-date-picker'
              value={startDate}
              views={['year', 'month', 'day']}
              PopperProps={{     
                sx: datePickerStyles 
               }}
              // onChange={(newValue:any) => setStartDate(newValue)}
              onChange={(newValue:any) => {
                const currentTime = new Date();
                // newValue.setHours(currentTime.getHours());
                // newValue.setMinutes(currentTime.getMinutes());
                // newValue.setSeconds(currentTime.getSeconds());
                setStartDate(newValue);
                setEndDate(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  id={`${customDateId}-startDatePicker`}
                  {...params}
             
                  variant="standard"
                  placeholder="DD/MM/YYYY"
                  className={classes.datePicker}
                 
                />
              )}
              
              inputFormat="dd/MM/yyyy"
              disableFuture
            />
          </div>
          
          <div id={`${customDateId}-endDateContainer`}>
            <Typography id={`${customDateId}-endDateLabel`} variant="body2" className={classes.datePickerLabel}>
              End Date
            </Typography>
            <DatePicker
              className='custom-date-picker'
              views={['year', 'month', 'day']}
              PopperProps={{     
                sx: datePickerStyles 
                }}
              value={endDate}
              onChange={(newValue:any) => {
                const currentTime = new Date();
                // newValue.setHours(currentTime.getHours());
                // newValue.setMinutes(currentTime.getMinutes());
                // newValue.setSeconds(currentTime.getSeconds());
                newValue?.setHours('23');
                newValue?.setMinutes('59');
                newValue?.setSeconds('59');
                setEndDate(newValue);
              }}
              // onChange={(newValue:any) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField
                  id={`${customDateId}-endDatePicker`}
                  {...params}
                  variant="standard"
                  placeholder="DD/MM/YYYY"
                  className={classes.datePicker}
                 
                />
              )}
              disableFuture
              inputFormat="dd/MM/yyyy"
              shouldDisableDate={disableEndDate}

             
            />
          </div>
        </div>
        <Divider id="divider" className={classes.divider} />
        <div className={classes.actions}>
          <Button 
            sx={{fontWeight:400}}
            onClick={() => setAnchorEl(null)} 
            className={`${classes.button} ${classes.cancelButton}`}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleOkClick}
            className={`${classes.button} ${classes.okButton}`}
          >
            OK
          </Button>
        </div>
      </Popover>
    </LocalizationProvider>
  );
};

export default CustomDate;
