
const isEmpty = (obj) => Object?.keys(obj)?.length === 0;


const isEmptyKey = (obj) =>{

   const keys = Object.keys(obj);

   // Check if the object has exactly one key and the key is an empty string
   if (keys.length === 1 && keys[0] === '') {
     return true
   }

   return false

}

export const convertIntoArray = (data) =>{

   if(isEmpty(data) || isEmptyKey(data)){
      return []
   }
   return Object.entries(data)?.map(([name, value]) => ({ name, value }));
}
