import React from 'react';
import CustomTable from '../../components/common/customTable';
import { Typography } from '@mui/material';

const DomainsPage = ({ id, data }) => {
  const columns = [
    { header: 'Domain name', field: 'domain_name' },
    { header: 'Domain URL', field: 'domain_url' },
    { header: 'Operational mode', field: 'current_operational_mode' },
  ];

  if(data?.length === 0){
    return <Typography>No domains Available</Typography>
  }

  return (
    <CustomTable id={id} columns={columns} data={data} />
  )
};

export default DomainsPage;
