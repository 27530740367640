
import { Box, Paper } from "@mui/material";

import PageNotFound from '../assets/icons/svg/pageNotFound.svg'


const NoPageFound = ()=>{
    return (
            <Box
                width={"100%"}
                mt={'4%'}
                borderRadius={'1%'}
                >
            
            <div style={{
               width:"100%",
               display:"flex",
               flexDirection:"column",
               alignItems:"center"
                }}>
                <img src={PageNotFound} alt="page not found"/>
            
            </div>
            </Box>
        );
    
}

export default NoPageFound