import { Box } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import cyberwaspLogo from '../../../assets/icons/svg/logo.svg'
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  logo: {
    width: "10rem", 
    height: "auto" ,
    '@media (min-width:2560px)': {
      width: "12rem", 
    },
    '@media (min-width:3840px)': {
      minHeight: '90px !important', // Adjust the value for larger screens

    },

  },
}));

const Logo = ({id}) => {

  const classes = useStyles()

  const navigate = useNavigate()
  return (
        <Box
          id={id}
          display="flex"
          justifyContent="flex-start"
          className="logo"
          sx={{ cursor: "pointer",width:{xs:"100%",sm:"40%"}}}
          onClick={() => navigate("/")}
        >
          <img
            id={`${id}-image`}
            src={cyberwaspLogo}
            alt="cybersophy logo"
            className={classes.logo}
          />
        </Box>
      );
}

export default Logo
