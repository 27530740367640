export const processData = (rawData) => {
  const methodsToShow = ['GET', 'POST', 'PUT'];

  // Filter rawData to include only methods in methodsToShow
  let processedData = rawData.filter(item => methodsToShow.includes(item.name));

  // Filter out methods already included in processedData
  let others = rawData.filter(item => !methodsToShow.includes(item.name));

  // Calculate the sum of values for 'Others'
  const othersValue = others.reduce((sum, item) => sum + item.value, 0);

  // If there are 'Others' to show, add it to processedData
  if (othersValue > 0) {
      processedData.push({ 
          name: 'Others', 
          value: othersValue,
          breakdown: others  // Optionally include breakdown if needed
      });
  }

  return processedData;
};
