import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { CustomTableCell } from '../../../screen/WafAdmin/reuseStyles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowY:'auto',
    width: '100%',
    height:'90%',
    // boxShadow: '0px 4px 20px 0px #4A556833',
    marginBottom: theme.spacing(1), // Optional: Adjust spacing as needed
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1
  
  },
  tableHeaderCell: {
    color: '#0C87F0 !important',
  },
}));

const CustomTable = ({ id, columns, data }) => {
  const classes = useStyles();

  return (
    <TableContainer id={id} className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns?.map((column, index) => (
              <CustomTableCell sx={{fontWeight:600}} key={index} className={classes.tableHeaderCell}>
                {column?.header}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns?.map((column, colIndex) => (
                <CustomTableCell key={colIndex}>
                  {column?.render ? column.render(row) : row[column.field]}
                </CustomTableCell>
              ))}
            </TableRow>
            
          ))} 
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
