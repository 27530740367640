import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import './tablestyles.css'

import {
  Box,
  Button,
  Container,
  IconButton,
  Modal, TextField,
  Typography
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  addOrEditOrDeleteUser,
  // getAllGroups,
  // getAllRoles,
  getAllUsersWithPagination,
} from "./userService";
import CreateUser from "./CreateUser";
import CONSTANTS from "../../../constants/constants";
import { useDispatch } from "react-redux";
import EditUser from "./EditUser";
import { makeStyles } from "@material-ui/core";
import { deleteUserFromClient, getAllPaginatedClients, mapUserAndClient } from "../Client/clientServices";
import AutoCompleteClients from "../Client/AutoCompleteClients";
import UserAddIcon from '../../../assets/icons/svg/user.svg'
import { CustomTablePagination, LightTooltip } from "../adminStyles";
import { CustomButton, CustomDiv, CustomFormLabel, CustomTableCell, CustomTableContainer, CustomTableHead, HeadingStyled, StyledImg, StyledMuiIcon } from "../reuseStyles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { pushNotification } from "../../../redux/notification/notificationActions";
import { useEffect, useState } from "react";
import DeleteConfirmationDialog from "../../../components/common/customDeleteBox/DeleteConfirmationDialog";

export const useStyles = makeStyles((theme) => ({
  
    tmTableHeading: {
      color: "#4B6D8F !important",
      fontSize: "16px !important",
      fontWeight: 400,
      fontStyle: "bold !important",
      width:120,
    },

}));

const headers =[
  {id:"userName",label:"User Name",align:"left"},
  {id:"firstName",label:"First Name",align:"left"},
  {id:"lastName",label:"Last Name",align:"left"},
  {id:"mobile",label:"Mobile No.",align:"center"},
  {id:"Email",label:"Email",align:"center"},
  {id:"clientName",label:"Client Name",align:"center"},
  {id:"actions",label:"Actions",align:"center"},
]

export default function Users() {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserData, setSelectedUserData] = useState<any>({});
  const [clientsList,setClientsList] = useState([])
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for the delete dialog
  const [userToDelete, setUserToDelete] = useState<any>(null); // State to store user for deletion
  const [userId,setUserId] = useState('')
  const [clientName,setClientName] = useState("")
  const [isAddClient,setIsAddClient] = useState(false)
  const [data,setData] = useState<any>({})
  const [selectedUserClient, setSelectedUserClient] = useState<string>("");

  // const [roles, setRoles] = React.useState<any>(["waf-user"]);
  // const [groups, setGroups] = React.useState<any>([]);

  const handleGetAllUsers = async (page, size, searchTerm?) => {
    const response = await getAllUsersWithPagination(
      page + 1,
      size,
      searchTerm
    );
    
    setTotalRecords(response?.data?.totalElements);
    setRowData(response?.data?.content?.map(item=>{
    
      const userdata = item?.userData 
      return ({
        ...userdata,
        id:item?.userId,
        maxHeight:200
      })
    }));
   
      
  };



  React.useEffect(() => {
    handleGetAllUsers(page, rowsPerPage, searchTerm);
  }, [page, rowsPerPage, searchTerm, isCreateUser, isEditUser]);




  const handleGetUserId  = async (event,row)=>{
    setIsAddClient(true)
    setSelectedUser(row?.id);
    setData(row)

    // console.log("row user in handleGetUserId",row)
    // const res:any = await getAllUsersWithKeycloakId(row?.emailId)
    setUserId(row?.id)
  }

  useEffect(()=>{
    handleGetAllPaginatedClients()
    
  },[clientName,selectedUserClient,isEditUser])

  
  const handleGetAllPaginatedClients = async()=>{
    const res:any = await getAllPaginatedClients(1,10,"client_name",selectedUserClient)

    // console.log("clientlist ",res?.data?.clients)
    setClientsList(res?.data?.clients)
  }


  const handleChangeClient =async (newValue,clientId)=>{

    setClientName(newValue)
    
    const clientUserData = {client_id:clientId,user_id:userId}


    const res:any = await mapUserAndClient(clientUserData)


    if (res?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: res?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      
      handleGetAllUsers(page, rowsPerPage, searchTerm);

    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: res?.error,
          type: CONSTANTS.ERROR,
        })
      );
    }
  
    
    handleGetAllPaginatedClients()

    const newData = {...data,clientName:newValue}
        
    setIsAddClient(false);
    const response = await addOrEditOrDeleteUser("update", selectedUser,newData);

    handleGetAllUsers(page, rowsPerPage, searchTerm);
    setClientName("")
  
  }

  const handleClose = ()=>{
    setIsAddClient(false)
  }

  // const handleGetRoles = async () => {
  //   const response = await getAllRoles();
  //   if (response?.success) {
  //     setRoles(response?.data);
  //   } else {
  //     setRoles([]);
  //   }
  // };

  // const handleGetGroups = async () => {
  //   const response: any = await getAllGroups();
  //   if (response?.success) {
  //     console.log(response.data);
  //     let filteredArray = response?.data.filter((obj) =>
  //       obj?.name.startsWith("task")
  //     );
  //     setGroups(filteredArray);
  //   } else {
  //     setGroups([]);
  //   }
  // };

 
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleDeleteUser = (user) => {
    setUserToDelete(user); // Set the user to be deleted
    setOpenDeleteDialog(true); // Open the delete confirmation dialog
  };

  const confirmDelete = async () => {
  
    // console.log("userToDelete ",userToDelete)
    if (userToDelete) {

      // Your deletion logic here, e.g., API call to delete the user
      const res: any = await deleteUserFromClient(userToDelete.id);
      if (res?.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: res?.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: res?.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
  
      const response = await addOrEditOrDeleteUser("delete", userToDelete.id, null, userToDelete.userName);
      if (response?.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        handleGetAllUsers(page, rowsPerPage, searchTerm);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
      setOpenDeleteDialog(false); // Close the dialog after confirmation
      setUserToDelete(null); // Reset the user to delete
    }
  };

  const handleEditUser = (row)=>{
    setSelectedUser(row?.id);
    setSelectedUserClient(row?.clientName)
    setSelectedUserData(row)
    setIsEditUser(true);
  }
  

  const cancelDelete = () => {
    setOpenDeleteDialog(false); // Close the dialog without deleting
    setUserToDelete(null); // Reset the user to delete
  };

  // const handleDeleteUser = async (user) => {
  //   const res:any = await deleteUserFromClient(userId)
  //   if (res?.success) {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: res?.message,
  //         type: CONSTANTS.SUCCESS,
  //       })
  //     );
    
  //   } else {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: res?.message,
  //         type: CONSTANTS.ERROR,
  //       })
  //     );
  //   }
  
  //   const response = await addOrEditOrDeleteUser(
  //     "delete",
  //     user.id,
  //     null,
  //     user.userName
  //   );
  //   if (response?.success) {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: response.message,
  //         type: CONSTANTS.SUCCESS,
  //       })
  //     );
      
  //     handleGetAllUsers(page, rowsPerPage, searchTerm);

  //   } else {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: response.message,
  //         type: CONSTANTS.ERROR,
  //       })
  //     );
  //   }
  // };
  // React.useEffect(() => {
  //   // handleGetRoles();
  //   // handleGetGroups();
  // }, []);

  const StyledEditIcon = StyledMuiIcon(ModeEditOutlineOutlinedIcon)
  const StyledDeleteIcon = StyledMuiIcon(DeleteOutlineOutlinedIcon)
  
  return (
    <Box 
      sx={{p:"4%",
        overflow:"auto", height:`calc(100vh - 80px)`}} width={"100%"}>
      <Modal
        open={isCreateUser}
        onClose={() => {
          setIsCreateUser(false);
        }}
      >
        <Box 
        >
          <CreateUser
            show={isCreateUser}
            setShow={setIsCreateUser}
            // roles={roles}
            // groups={groups}
          />
        </Box>
      </Modal>
      <Modal
        open={isAddClient}
        onClose={() => {
          setIsAddClient(false);
        }}
        >
        <Box
          sx={{
            display:'flex',
            flexWrap:"wrap",
            alignItems:"center",
            position:"absolute",
            backgroundColor:"#FFFFFF",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width:"80%",
            maxWidth:"38rem",
            padding:"2%"
          }}
        >
          <Box display={"flex"} width="100%" justifyContent="space-between" alignItems="center">
          <CustomFormLabel color={"blue"}>Add the Client to User</CustomFormLabel>
           <Button
              sx={{ color: "blue"}}
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </Button>
            </Box>
          <AutoCompleteClients 
            
            handleChangeClient={handleChangeClient} 
            initialClientName={clientName}
            />
        </Box>
      </Modal>

      <Modal
        open={isEditUser}
        onClose={() => {
          setIsEditUser(false);
        }}
      >
        <Box className="createTaskModalContainerTm">
          <EditUser
            show={isEditUser}
            setShow={setIsEditUser}
            selectedUser={selectedUser}
            selectedUserClient={selectedUserClient}
            selectedUserData={selectedUserData}
            // roles={roles}
            // groups={groups}
            clientsList = {clientsList}
            setSelectedUserClient={setSelectedUserClient}
            handleGetAllUsers={()=>  handleGetAllUsers(page, rowsPerPage, searchTerm)            }

          />
        </Box>
      </Modal>
      <DeleteConfirmationDialog
        title="Delete User?"
        description="Are you sure you want to delete this User?"
        open={openDeleteDialog}
        onConfirm={confirmDelete}
        onClose={cancelDelete}
      />

      <Box display={"flex"} flexDirection={"column"}>
      <HeadingStyled color="rgb(5, 136, 211)" variant='h4'>Users</HeadingStyled>
        <Box display={"flex"} flexDirection={"row"} mt={"1rem"} mb={"2rem"}>
          <TextField
            type={"search"}
            placeholder={"search"}
            sx={{   
              width:"18%",
              minWidth:"10em",
              justifyContent: "flex-start",
              ".MuiInputBase-input":{
                height:{lg:"1.5rem",xl:"1.8rem"}
             }
            }}
            size={"small"}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <CustomButton
            // className="create-button"
            sx={{ 
              lineHeight:"100%",
              justifyContent: "flex-end",ml:"auto",
              textTransform:"none"
            }}
            variant={"contained"}
            onClick={() => setIsCreateUser(true)}
          >
            Create New
          </CustomButton>
        </Box>
      </Box>
      <CustomTableContainer 
        sx={{
          overflow:"auto",
          width: "100%"
          
        }}>
        <Table
          sx={{ width: "100%"}}
          stickyHeader

        >
          <TableHead >
          <TableRow>
                {headers?.map((header:any)=>{
                  return <CustomTableHead 
                    className="table-header"
                    sx={{
                      textAlign:header?.align,
                      paddingLeft:"1.8%",
                      fontWeight:600,
                      fontStyle:"normal",
                     
                    }} key={header?.id}>{header?.label}</CustomTableHead>
              
                })}
              </TableRow>
            
          </TableHead>
          <TableBody>
            {rowData?.map((row) => (
              <TableRow
                key={row?.userName}
                hover
              >
                <CustomTableCell 
                      title={row?.userName}>
                      {row?.userName}
                </CustomTableCell>
                <CustomTableCell align="left" title={row?.firstName}>
                      {row?.firstName}
                </CustomTableCell>
                <CustomTableCell title={row?.lastName} align="left">
                    {row?.lastName}                    
                </CustomTableCell>
                <CustomTableCell align="center" title={row?.mobileNumber}>
                    {row?.mobileNumber}
                </CustomTableCell>
                <CustomTableCell align="left" title={row?.emailId}>
                     {row?.emailId}
                </CustomTableCell>
                <CustomTableCell  
                  className="table-cell"
                  align="center"
                  >
                   <Box  sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>

                     {(row?.clientName === "" || row.clientName ===undefined) && <IconButton onClick={(event) => handleGetUserId(event,row)}>
                          <StyledImg  title="Add Client" src={UserAddIcon}/>
                       
                      </IconButton>}
                      {row?.clientName !== "" && <Typography title={row?.clientName} variant="h6">{row?.clientName}</Typography>}
                   </Box>
                </CustomTableCell>
                <CustomTableCell>
                  <Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box>
                        <IconButton title="Edit"
                            onClick={()=>handleEditUser(row)}
                            
                          >
                            <StyledEditIcon sx={{color:"blue"}}/>
                          </IconButton>                        
        
                      </Box>
                      <Box>
                          <IconButton title="Delete" onClick={() => handleDeleteUser(row)}>
                            <StyledDeleteIcon sx={{color:"red"}}/>
                          </IconButton>
                      </Box>
  
                    </Box>
                  </Box>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CustomTableContainer>
      <Paper sx={{width:"100%",flexGrow:1,mt:"0.5%",pb:0,display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
        <CustomTablePagination
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
        />
      </Paper>
    </Box>
  );
}
