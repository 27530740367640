import CONSTANTS from "./constants";

export const TEMPLATE_UPLOAD = '/dms/v1/documents?documentTypeId=';
export const TEMPLATE_DOWNLOAD = '/dms/v1/documents';
export const DELETE_DOCUMENT = '/dms/v1/documents?id='
export const GENERATE_OTP = '';
export const VERIFY_OTP = '';
export const KEYCLOAK_TOKEN = 'auth/realms/cybersophy/protocol/openid-connect/token';

export const USER_PROCESS_DEFINITION_KEY = "Process_844844770998710300";
export const GROUP_PROCESS_DEFINITION_KEY = "Process_tyoovr6";
export const ACTION_EDIT = "edit";
export const ACTION_DELETE = "delete";

export const TASKMANAGEMENT_USER_ENDPOINT = "/task-management/v1/user-info";
export const TASKMANAGEMENT_USERS_ENDPOINT = "/task-management/v1/users";
export const USERS_ENDPOINT = "/accounts/v1/users";
export const USERS_GROUPS = "/accounts/v1/groups";
export const USERS_ROLES = "/accounts/v1/keycloak/roles";
export const USER_PREFERENCE = "/accounts/v1/users/preferences";
export const MENUS = "/accounts/v1/roles/menu"

export const START_PROCESS = "/workflow/v1/process/start";


export const CLIENT_ACCOUNT = "client-account";
export const CLIENT_LIST = "clients-list"
export const UPDATE_CLIENT="update-client"
export const DELETE_CLIENT ="delete-client"
export const ADD_URL ="add-url"
export const MAP_CLIENT_USER ="map-client-user"
export const GET_CLIENT_URLS = "get-urls"

export const DELETE_USER_FROM_CLIENT = "delete-client-user"
export const DELETE_URL_FROM_CLIENT = "delete-client-url"
export const UPDATE_URL_IN_CLIENT = "edit-client-url"
export const SEND_REPORT = "waf-report"
export const ATTACKS_BLOCKED_INFO ='attacks-blocked-info'


// EULA endpoints

export const EULA_CHECK_ENDPOINT = '/dashboard/check-eula'
export const EULA_SET_ENDPOINT = '/client_user/map-client-user/'
export const EULA_TEXT_DOWNLOAD_ENDPOINT = '/dms/v1/documents/download?id='


export const BASE_GATE_WAY_URL = sessionStorage.getItem(CONSTANTS.GATEWAY_URL)

export const BASE_URL = `${BASE_GATE_WAY_URL}`

// export const BASE_URL = 'http://127.0.0.1:8000/api'

// export const BASE_URL = `${BASE_GATE_WAY_URL}${process.env.PUBLIC_URL}`


export const userId = sessionStorage.getItem(CONSTANTS.AWGMENT_USER_ID)

export const USER_PROFILE_ENDPOINT = '/dashboard/user-profile/'


export const DOMAINS_BY_CLIENT_ENDPOINT = '/dashboard/clients/domains'

export const getHitsAndAttacksEndpoint = (domainId,startAndEndDateTime)=>`/dashboard/hits-attacks/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`

export const getGeoMapEndPoint = (domainId,startAndEndDateTime,filterType)=> `/dashboard/geo-map-details/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}&filter_type=${filterType}`

export const getAttacksBySeverityEndpoint = (domainId,startAndEndDateTime)=>  `/dashboard/attacks-by-severity/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`

export const getAttacksByCategoryEndpoint = (domainId,startAndEndDateTime)=>  `/dashboard/attacks-by-category/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`

export const getAttacksTrednlineEndpoint = (domainId,startAndEndDateTime,timeline)=>  `/dashboard/trendline/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}&timeline=${timeline}`

export const getViewDetailsEndpoint = (domainId,uniqueId,startAndEndDateTime) => `/dashboard/view-attack/${domainId}?unique_id=${uniqueId}&start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`

export const getUserRequestStatsEndpoint = (domainId,startAndEndDateTime)=>  `/dashboard/statistics/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`

export const getDownloadPDFApiEndpoint = (domainId,startAndEndDateTime)=> `/dashboard/download-pdf/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`
 
export const getAttacksInformationEndpoint = (domainId,startAndEndDateTime,page,searchTerm) =>  `/dashboard/attacks-info/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}&page=${page}&search=${searchTerm}`




// awgment api

const dmsDocId = sessionStorage.getItem(CONSTANTS.DMS_DOC_ID)

 export const TEMPLATE_UPLOAD_ENDPOINT = `${sessionStorage.getItem(CONSTANTS.AWGMENT_API)}${TEMPLATE_UPLOAD}${dmsDocId}`;
 export const TEMPLATE_DOWNLOAD_ENDPOINNT = `${sessionStorage.getItem(CONSTANTS.AWGMENT_API)}${TEMPLATE_DOWNLOAD}`;
 


// client screen api endpoints

export const getClientsDomainsListEndpoint = (page,rowsPerPage,search) => `/client_user/clients-domains-list?page=${page}&limit=${rowsPerPage}${search}`
export const ADD_CLIENT_API_ENDPOINT = `/client_user/add-client`
export const getClientUpdationEndpoint = (clientId)=> `/client_user/client/${clientId}`
export const getDeleteClientEndpoint = (clientId) =>`/client_user/client/${clientId}`
export const CLIENT_USER_MAPPING_ENDPOINT = `/client_user/map-client-user`
export const getUpdateClientUserMappingEndpoint  = `${BASE_URL}/client_user/map-client-user`
export const DELETE_CLIENT_USER_MAPPING_API_ENDPOINT = '/client_user/map-client-user'
export const getDeleteDomainEndpoint = (domainId) => `/client_user/domain/${domainId}`
export const ADD_CLIENT_DOMAIN_API_ENDPOINT = '/client_user/add-domain'
export const getEditDomainEndpoint = (domainId) => `/client_user/domain/${domainId}`

export const getCombinedResponseEndpoint = (domainId,startAndEndDateTime) =>`/dashboard/main/${domainId}?start_date=${startAndEndDateTime?.from}&end_date=${startAndEndDateTime?.to}`