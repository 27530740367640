import { Box, Typography } from '@mui/material'
import React from 'react'

const Logout = () => {
  return (
    <Box color={"#0046FF"} pt={10} display={"flex"} flexDirection={'column'} alignItems={"center"} justifyContent={"center"}>
      <div className='eula_loader'></div>
       <Typography pt={5} color={"#0046FF"} variant='h3'>Logging out...</Typography> 
    </Box>
  )
}

export default Logout
