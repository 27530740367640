// DonutChart.js
import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts';
import useResponsiveDimensions from '../../../components/common/useResponsiveDimensions';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    label:{
      fontSize:'1rem',
      '@media (min-width:2560px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem',
      },
    }

}))

const DonutChart = ({id, percentage, severity }) => {
  const data = [
    { name: 'Completed', value: percentage },
    { name: 'Remaining', value: 100 - percentage },
  ];

  const { width, height } = useResponsiveDimensions();

  const innerRadius = width / 2.6;
  const outerRadius = width / 2;

  const classes = useStyles()

  const getGradient = (severity) => {
    switch (severity) {
      case 'High':
        return 'url(#highGradient)';
      case 'Medium':
        return 'url(#mediumGradient)';
      case 'Low':
        return 'url(#lowGradient)';
      default:
        return '#8884d8';
    }
  };

  const getTextColor = (severity) => {
    switch (severity) {
      case 'High':
        return '#FF4B55';
      case 'Medium':
        return '#FFB441';
      case 'Low':
        return '#0095FF';
      default:
        return '#8884d8';
    }
  };

  return (
    <PieChart id={id} width={width} height={height}>
      <defs>
        <linearGradient id="highGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#FF4B55', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#FAB446', stopOpacity: 1 }} />
        </linearGradient>
        <linearGradient id="mediumGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#FFB441', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#FFE15A', stopOpacity: 1 }} />
        </linearGradient>
        <linearGradient id="lowGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#A1FF8B', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#0095FF', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <Pie
        id={`${id}-severityDonut`}
        data={data}
        cx="50%"
        cy="50%"
        tooltipType='none'
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill="#8884d8"
        dataKey="value"
      >
        {data?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={index === 0 ? getGradient(severity) : '#E0E0E0'} />
        ))}
        <Label
          id={`${id}-severityLabel`}
          value={`${percentage.toFixed(2)}%`}
          position="center"
          fill={getTextColor(severity)}
          className={classes.label}
          style={{ fontWeight: 'bold' }}
        />
      </Pie>
      {/* <Tooltip /> */}
    </PieChart>
  );
};

export default DonutChart;


