import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
    //   backgroundColor: theme.palette.error.main,
      color: "#0046FF !important",
      textAlign: 'left',
      fontSize:'1rem !important'
    },
    dialogContent: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    dialogActions: {
      justifyContent: 'center',
    },
    button:{
        color:'#0046FF !important'
    },
    confirmButton: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    cancelButton: {
      marginRight: theme.spacing(1),
    },
  }));
  

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title = 'Delete Client?',
  description = 'Are you sure you want to delete this Client?',
}) => {
    const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="delete-confirmation-dialog-title">
      <DialogTitle className={classes.dialogTitle} id="delete-confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button className={classes.button} onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
