// import { IconButton, Popover, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
// import { useState } from "react";
// import { useDispatch } from "react-redux";
// import CONSTANTS, { URL_PATTERN } from "../../../constants/constants";
// import { addOrEditOrDeleteClient } from "./clientServices";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { useFormik } from "formik";
// import * as Yup from 'yup';
// import { pushNotification } from "../../../redux/notification/notificationActions";
// import moment from "moment";

// interface AddDomainToClientProps {
  
//   clientId: string;
//   handleGetPaginatedClients?: any;
// }

// const AddDomainToClient: React.FC<AddDomainToClientProps> = ({ clientId, handleGetPaginatedClients }) => {
//   const [isPopoverOpen, setPopoverOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleAddCircleButtonClick = (event) => {
//     setAnchorEl(event.currentTarget);
//     setPopoverOpen(true);
//   };

//   const handleClosePopover = () => {
//     setPopoverOpen(false);
//   };

//   const dispatch = useDispatch();

//   const initialValues = {
//     client_id: clientId,
//     domain_name: "",
//     domain_url: "",
//     db_name: "",
//     ip_address: "",
//     current_mode: "Off",
//     hosting_service_name: "AWS",
//     dns_mapped: true,
//     mapped_on: moment().format('DD/MM/YYYY HH:mm:ss'),
//   };

//   const formPayload = (values: any) => ({
//     domain_name: values?.domain_name,
//     domain_url: values?.domain_url,
//     db_name: values?.db_name,
//     ip_address: values?.ip_address,
//     current_mode: values?.current_mode,
//     hosting_service_name: values?.hosting_service_name,
//     dns_mapped: values?.dns_mapped,
//     mapped_on: values?.mapped_on,
//     client_id: values?.client_id,
//   });

//   const postFormValues = (values: any) => {
//     const postData = formPayload(values);
//     handlePostApiRequest(postData);
//   };

//   const handlePostApiRequest = async (data) => {
//     const response: any = await addOrEditOrDeleteClient("add-domain", null, data);

//     if (response?.success) {
//       handleClosePopover()
//       handleGetPaginatedClients();
//       dispatch(
//         pushNotification({
//           isOpen: true,
//           message: response?.message,
//           type: CONSTANTS.SUCCESS,
//         })
//       );

//     } else {
//       handleClosePopover()

//       dispatch(
//         pushNotification({
//           isOpen: true,
//           message: response?.message,
//           type: CONSTANTS.ERROR,
//         })
//       );
//     }
//   };

//   const handleSubmit = async (values: any, setSubmitting: any) => {
//     setSubmitting(true);
//     postFormValues(values);
//   };

//   const formOne = useFormik({
//     initialValues: initialValues,
//     validationSchema: Yup.object({
//       domain_name: Yup.string()
//         // .matches(URL_PATTERN, "Invalid domain name")
//         .required("Domain name is required"),
//       domain_url: Yup.string()
//         .matches(URL_PATTERN, "Invalid URL")
//         .required("Domain URL is required"),
//       db_name: Yup.string().required("Database name is required"),
//       ip_address: Yup.string().required("IP address is required"),
//       current_mode: Yup.string().required("Current mode is required"),
//       hosting_service_name: Yup.string().required("Hosting service name is required"),
//       dns_mapped: Yup.boolean().required("DNS mapped is required"),
//       mapped_on: Yup.string().required("Mapped on date is required")
//     }),
//     onSubmit: (values, { setSubmitting }) => {
//       handleSubmit(values, setSubmitting);
//     },
//   });

//   return (
//     <>
//       <IconButton onClick={handleAddCircleButtonClick}>
//         <AddCircleOutlineIcon />
//       </IconButton>
//       <Popover
//         open={isPopoverOpen}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//       >
//         <form onSubmit={formOne.handleSubmit} style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
//           <TextField
//             id="domain_name"
//             name="domain_name"
//             label="Domain Name"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.domain_name}
//             helperText={formOne.touched.domain_name && formOne.errors.domain_name}
//             error={formOne.touched.domain_name && Boolean(formOne.errors.domain_name)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="domain_url"
//             name="domain_url"
//             label="Domain URL"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.domain_url}
//             helperText={formOne.touched.domain_url && formOne.errors.domain_url}
//             error={formOne.touched.domain_url && Boolean(formOne.errors.domain_url)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="db_name"
//             name="db_name"
//             label="Database Name"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.db_name}
//             helperText={formOne.touched.db_name && formOne.errors.db_name}
//             error={formOne.touched.db_name && Boolean(formOne.errors.db_name)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="ip_address"
//             name="ip_address"
//             label="IP Address"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.ip_address}
//             helperText={formOne.touched.ip_address && formOne.errors.ip_address}
//             error={formOne.touched.ip_address && Boolean(formOne.errors.ip_address)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="current_mode"
//             name="current_mode"
//             label="Current Mode"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.current_mode}
//             helperText={formOne.touched.current_mode && formOne.errors.current_mode}
//             error={formOne.touched.current_mode && Boolean(formOne.errors.current_mode)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <TextField
//             id="hosting_service_name"
//             name="hosting_service_name"
//             label="Hosting Service Name"
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.hosting_service_name}
//             helperText={formOne.touched.hosting_service_name && formOne.errors.hosting_service_name}
//             error={formOne.touched.hosting_service_name && Boolean(formOne.errors.hosting_service_name)}
//             sx={{ marginBottom: "1rem" }}
//           />
//             <FormControlLabel
//             control={
//               <Checkbox
//                 id="dns_mapped"
//                 name="dns_mapped"
//                 checked={formOne.values.dns_mapped}
//                 onChange={formOne.handleChange}
//               />
//             }
//             label="DNS Mapped"
//             sx={{ marginBottom: "1rem" }}
//           />

//           <TextField
//             id="mapped_on"
//             name="mapped_on"
//             label="Mapped On"
//             type="text"  // Adjust input type as needed
//             size="small"
//             onBlur={formOne.handleBlur}
//             onChange={formOne.handleChange}
//             value={formOne.values.mapped_on}  // Example value binding
//             helperText={formOne.touched.mapped_on && formOne.errors.mapped_on}
//             error={formOne.touched.mapped_on && Boolean(formOne.errors.mapped_on)}
//             sx={{ marginBottom: "1rem" }}
//           />
//           <Button type="submit" variant="contained" color="primary" disabled={formOne.isSubmitting}>
//             Add URL
//           </Button>
//         </form>
//       </Popover>
//     </>
//   );
// };

// export default AddDomainToClient;



// import React, { useEffect } from 'react';
// import { Popover, TextField, Button, FormControlLabel, Checkbox, IconButton } from "@mui/material";
// import { useFormik } from "formik";
// import * as Yup from 'yup';
// import { useDispatch } from "react-redux";
// import CONSTANTS, { URL_PATTERN } from "../../../constants/constants";
// import { addOrEditOrDeleteClient } from "./clientServices";
// import { pushNotification } from "../../../redux/notification/notificationActions";
// import moment from "moment";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// interface AddDomainToClientProps {
//   clientId: string;
//   handleGetPaginatedClients?: () => void;
//   initialValues?: any;
//   isEditing?: boolean;
//   onClose?: any;
//   anchorEl?: HTMLElement | null;
//   handleAddDomainBtnClick?:any;
// }

// const AddDomainToClient: React.FC<AddDomainToClientProps> = ({ 
//   clientId="", 
//   handleGetPaginatedClients, 
//   initialValues = null,
//   isEditing = false,
//   onClose,
//   anchorEl,handleAddDomainBtnClick
// }) => {
//   const dispatch = useDispatch();


//   const defaultInitialValues = {
//     client_id: clientId,
//     domain_name: "",
//     domain_url: "",
//     db_name:"",
//     ip_address:"",
//     current_mode:"Off",
//     hosting_service_name: "AWS",
//     dns_mapped:true,
//     mapped_on: moment().format('DD/MM/YYYY HH:mm:ss'),
//     // client_id: clientId,
//     // domain_name: initialValues?.domain_name || "",
//     // domain_url: initialValues?.domain_url || "",
//     // db_name: initialValues?.db_name || "",
//     // ip_address: initialValues?.ip_address || "",
//     // current_mode: initialValues?.current_mode || "Off",
//     // hosting_service_name: initialValues?.hosting_service_name || "AWS",
//     // dns_mapped: initialValues?.dns_mapped ?? true,
//     // mapped_on: initialValues?.mapped_on || moment().format('DD/MM/YYYY HH:mm:ss'),
//   };

//   const formInitialValues =  defaultInitialValues;

//   const validationSchema = Yup.object({
//     domain_name: Yup.string()
//       // .matches(URL_PATTERN, "Invalid domain name")
//       .required("Domain name is required"),
//     domain_url: Yup.string()
//       .matches(URL_PATTERN, "Invalid URL")
//       .required("Domain URL is required"),
//     db_name: Yup.string().required("Database name is required"),
//     ip_address: Yup.string().required("IP address is required"),
//     current_mode: Yup.string().required("Current mode is required"),
//     hosting_service_name: Yup.string().required("Hosting service name is required"),
//     dns_mapped: Yup.boolean().required("DNS mapped is required"),
//     mapped_on: Yup.string().required("Mapped on date is required")
//   });

//   const formik = useFormik({
//     initialValues: formInitialValues,
//     validationSchema: validationSchema,
//     onSubmit: async (values, { setSubmitting }) => {

//       console.log("Form submitted. Values:", values);
//       const postData = {
//         domain_name: values?.domain_name,
//         domain_url: values?.domain_url,
//         db_name: values?.db_name,
//         ip_address: values?.ip_address,
//         current_mode: values?.current_mode,
//         hosting_service_name: values?.hosting_service_name,
//         dns_mapped: values?.dns_mapped,
//         mapped_on: values?.mapped_on,
//         client_id: values?.client_id,
//       };

//       const action = isEditing ? "edit-domain" : "add-domain";

//       const response: any = await addOrEditOrDeleteClient(action, isEditing ? clientId : null, postData,initialValues?.domainId);

//       if (response?.success) {
//         if (handleGetPaginatedClients) {
//           handleGetPaginatedClients();
//         }
//         dispatch(pushNotification({
//           isOpen: true,
//           message: response?.message,
//           type: CONSTANTS.SUCCESS,
//         }));
//         onClose();
//       } else {
//         dispatch(pushNotification({
//           isOpen: true,
//           message: response?.detail?.message,
//           type: CONSTANTS.ERROR,
//         }));
//       }
//       setSubmitting(false);
//     },
//       enableReinitialize: true,

//   });

//   const handlePrefillData = async () => {
//    console.log("prefilled values ",initialValues)
//     formik.setFieldValue("domain_name", initialValues?.domain_name);
//     formik.setFieldValue("domain_url", initialValues.domain_url);
//     formik.setFieldValue("ip_address", initialValues.ip_address);
//     formik.setFieldValue("current_mode", initialValues.current_mode);
//     formik.setFieldValue("hosting_service_name", initialValues.hosting_service_name);
//     formik.setFieldValue("dns_mapped", initialValues.dns_mapped);
//     };

//   useEffect(() => {
//     if(isEditing){
//       handlePrefillData();
//     }
//   }, [isEditing]);
  
//   return (
//     <>
//     {!isEditing && <IconButton onClick={handleAddDomainBtnClick}>
//       <AddCircleOutlineIcon />
//     </IconButton>}
//     <Popover
//       open={Boolean(anchorEl)}
//       anchorEl={anchorEl}
//       onClose={onClose}
//       anchorOrigin={{
//         vertical: 'bottom',
//         horizontal: 'left',
//       }}
//       transformOrigin={{
//         vertical: 'top',
//         horizontal: 'left',
//       }}
//     >
//         <form onSubmit={formik.handleSubmit} style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
//           <TextField
//             id="domain_name"
//             name="domain_name"
//             label="Domain Name"
//             size="small"
//             value={formik.values.domain_name}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             error={formik.touched.domain_name && Boolean(formik.errors.domain_name)}
//             helperText={formik.touched.domain_name && formik.errors.domain_name}
//             sx={{ marginBottom: "1rem" }} />
//           <TextField
//             id="domain_url"
//             name="domain_url"
//             label="Domain URL"
//             size="small"
//             value={formik.values.domain_url}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             error={formik.touched.domain_url && Boolean(formik.errors.domain_url)}
//             helperText={formik.touched.domain_url && formik.errors.domain_url}
//             sx={{ marginBottom: "1rem" }} />
//           {!isEditing && <TextField
//             id="db_name"
//             name="db_name"
//             label="Database Name"
//             size="small"
//             value={formik.values.db_name}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             error={formik.touched.db_name && Boolean(formik.errors.db_name)}
//             helperText={formik.touched.db_name && formik.errors.db_name}
//             sx={{ marginBottom: "1rem" }} />}
//           <TextField
//             id="ip_address"
//             name="ip_address"
//             label="IP Address"
//             size="small"
//             value={formik.values.ip_address}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             error={formik.touched.ip_address && Boolean(formik.errors.ip_address)}
//             helperText={formik.touched.ip_address && formik.errors.ip_address}
//             sx={{ marginBottom: "1rem" }} />
//           <TextField
//             id="current_mode"
//             name="current_mode"
//             label="Current Mode"
//             size="small"
//             value={formik.values.current_mode}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             error={formik.touched.current_mode && Boolean(formik.errors.current_mode)}
//             helperText={formik.touched.current_mode && formik.errors.current_mode}
//             sx={{ marginBottom: "1rem" }} />
//           <TextField
//             id="hosting_service_name"
//             name="hosting_service_name"
//             label="Hosting Service Name"
//             size="small"
//             value={formik.values.hosting_service_name}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             error={formik.touched.hosting_service_name && Boolean(formik.errors.hosting_service_name)}
//             helperText={formik.touched.hosting_service_name && formik.errors.hosting_service_name}
//             sx={{ marginBottom: "1rem" }} />
//           <FormControlLabel
//             control={<Checkbox
//               id="dns_mapped"
//               name="dns_mapped"
//               checked={formik.values.dns_mapped}
//               onChange={formik.handleChange} />}
//             label="DNS Mapped"
//             sx={{ marginBottom: "1rem" }} />
//           <TextField
//             id="mapped_on"
//             name="mapped_on"
//             label="Mapped On"
//             type="text"
//             size="small"
//             value={formik.values.mapped_on}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             error={formik.touched.mapped_on && Boolean(formik.errors.mapped_on)}
//             helperText={formik.touched.mapped_on && formik.errors.mapped_on}
//             sx={{ marginBottom: "1rem" }} />
//           <Button type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
//             {isEditing ? 'Update Domain' : 'Add Domain'}
//           </Button>
//         </form>
//       </Popover></>
//   );
// };

// export default AddDomainToClient;


import { IconButton, Popover, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import CONSTANTS, { URL_PATTERN } from "../../../constants/constants";
import { addOrEditOrDeleteClient } from "./clientServices";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { pushNotification } from "../../../redux/notification/notificationActions";
import moment from "moment";

interface AddDomainToClientProps {
  
  clientId: string;
  handleGetPaginatedClients?: any;
  initialValues?: any;
  isEditing?: boolean;
  onClose?: any;
  anchorEl?: HTMLElement | null;
  handleAddDomainBtnClick?:any;
}

const AddDomainToClient: React.FC<AddDomainToClientProps> = ({ clientId, handleGetPaginatedClients }) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddCircleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const dispatch = useDispatch();

  const initialValues = {
    client_id: clientId,
    domain_name: "",
    domain_url: "",
    db_name: "",
    ip_address: "",
    current_mode: "Off",
    hosting_service_name: "AWS",
    dns_mapped: true,
    mapped_on: moment().format('DD/MM/YYYY HH:mm:ss'),
  };

  const formPayload = (values: any) => ({
    domain_name: values?.domain_name,
    domain_url: values?.domain_url,
    db_name: values?.db_name,
    ip_address: values?.ip_address,
    current_mode: values?.current_mode,
    hosting_service_name: values?.hosting_service_name,
    dns_mapped: values?.dns_mapped,
    mapped_on: values?.mapped_on,
    client_id: values?.client_id,
  });

  const postFormValues = (values: any) => {
    const postData = formPayload(values);
    handlePostApiRequest(postData);
  };

  const handlePostApiRequest = async (data) => {
    const response: any = await addOrEditOrDeleteClient("add-domain", null, data);

    if (response?.success) {
      handleClosePopover()
      handleGetPaginatedClients();
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );

    } else {
      handleClosePopover()

      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);
    postFormValues(values);
  };

  const formOne = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      domain_name: Yup.string()
        // .matches(URL_PATTERN, "Invalid domain name")
        .required("Domain name is required"),
      domain_url: Yup.string()
        .matches(URL_PATTERN, "Invalid URL")
        .required("Domain URL is required"),
      db_name: Yup.string().required("Database name is required"),
      ip_address: Yup.string().required("IP address is required"),
      current_mode: Yup.string().required("Current mode is required"),
      hosting_service_name: Yup.string().required("Hosting service name is required"),
      dns_mapped: Yup.boolean().required("DNS mapped is required"),
      mapped_on: Yup.string().required("Mapped on date is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
  });

  return (
    <>
      <IconButton onClick={handleAddCircleButtonClick}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <form onSubmit={formOne.handleSubmit} style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
          <TextField
            id="domain_name"
            name="domain_name"
            label="Domain Name"
            size="small"
            onBlur={formOne.handleBlur}
            onChange={formOne.handleChange}
            value={formOne.values.domain_name}
            helperText={formOne.touched.domain_name && formOne.errors.domain_name}
            error={formOne.touched.domain_name && Boolean(formOne.errors.domain_name)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            id="domain_url"
            name="domain_url"
            label="Domain URL"
            size="small"
            onBlur={formOne.handleBlur}
            onChange={formOne.handleChange}
            value={formOne.values.domain_url}
            helperText={formOne.touched.domain_url && formOne.errors.domain_url}
            error={formOne.touched.domain_url && Boolean(formOne.errors.domain_url)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            id="db_name"
            name="db_name"
            label="Database Name"
            size="small"
            onBlur={formOne.handleBlur}
            onChange={formOne.handleChange}
            value={formOne.values.db_name}
            helperText={formOne.touched.db_name && formOne.errors.db_name}
            error={formOne.touched.db_name && Boolean(formOne.errors.db_name)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            id="ip_address"
            name="ip_address"
            label="IP Address"
            size="small"
            onBlur={formOne.handleBlur}
            onChange={formOne.handleChange}
            value={formOne.values.ip_address}
            helperText={formOne.touched.ip_address && formOne.errors.ip_address}
            error={formOne.touched.ip_address && Boolean(formOne.errors.ip_address)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            contentEditable={false}
            disabled
            id="current_mode"
            name="current_mode"
            label="Current Mode"
            size="small"
            onBlur={formOne.handleBlur}
            onChange={formOne.handleChange}
            value={formOne.values.current_mode === 'Off'?'Transparent Mode':'Block Mode'}
            helperText={formOne.touched.current_mode && formOne.errors.current_mode}
            error={formOne.touched.current_mode && Boolean(formOne.errors.current_mode)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            id="hosting_service_name"
            name="hosting_service_name"
            label="Hosting Service Name"
            size="small"
            onBlur={formOne.handleBlur}
            onChange={formOne.handleChange}
            value={formOne.values.hosting_service_name}
            helperText={formOne.touched.hosting_service_name && formOne.errors.hosting_service_name}
            error={formOne.touched.hosting_service_name && Boolean(formOne.errors.hosting_service_name)}
            sx={{ marginBottom: "1rem" }}
          />
            <FormControlLabel
            control={
              <Checkbox
                id="dns_mapped"
                name="dns_mapped"
                checked={formOne.values.dns_mapped}
                onChange={formOne.handleChange}
              />
            }
            label="DNS Mapped"
            sx={{ marginBottom: "1rem" }}
          />

          <TextField
            id="mapped_on"
            name="mapped_on"
            label="Mapped On"
            type="text"  // Adjust input type as needed
            size="small"
            onBlur={formOne.handleBlur}
            onChange={formOne.handleChange}
            value={formOne.values.mapped_on}  // Example value binding
            helperText={formOne.touched.mapped_on && formOne.errors.mapped_on}
            error={formOne.touched.mapped_on && Boolean(formOne.errors.mapped_on)}
            sx={{ marginBottom: "1rem" }}
          />
          <Button type="submit" variant="contained" sx={{color:'#FFFFFF'}} disabled={formOne.isSubmitting}>
            Add URL
          </Button>
        </form>
      </Popover>
    </>
  );
};

export default AddDomainToClient;

