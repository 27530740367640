import {ATTACKS_BY_CATEGORY, DURATION_DATA,ATTACKS_BY_SEVERITY, ATTACKS_INFORMATION_TABLE, ATTACK_TRENDLINE_DATA, 
    GEO_MAP_DATA, MENUS_LIST, REQUEST_STATS_INFORMATION, START_AND_END_DATE_TIME, STATS_DATA, 
    SET_COMBINED_RESPONSE } from "../../constants/actions"

export const setDurationData = (durationData) => {
    return {
        type: DURATION_DATA,
        payload: durationData,
    };
};

export const setStartAndEndDateTime = (startAndEndDateTime) => {
    return {
        type: START_AND_END_DATE_TIME,
        payload: startAndEndDateTime
    }
}


export const setStatsData = (statsData) => {
    return {
        type: STATS_DATA,
        payload: statsData
    }
}


export const setGeoMapData = (geoMapData) => {
    return {
        type: GEO_MAP_DATA,
        payload: geoMapData
    }
}


export const setAttacksBySeverity = (attacksBySeverity) => {
    return {
        type: ATTACKS_BY_SEVERITY,
        payload: attacksBySeverity
    }
}

export const setAttacksByCategory = (attacksByCategory) => {
    return {
        type: ATTACKS_BY_CATEGORY,
        payload: attacksByCategory
    }
}

export const setAttacksTrendlineData = (attacksTrendlineData) => {
    return {
        type: ATTACK_TRENDLINE_DATA,
        payload: attacksTrendlineData
    }
}

export const setAttackInformationData = (attackInformationData) => {
    return {
        type: ATTACKS_INFORMATION_TABLE,
        payload: attackInformationData
    }
}


export const setRequestStats = (requestStats) => {
    return {
        type: REQUEST_STATS_INFORMATION,
        payload: requestStats
    }
}

export const setMenusList = (menusList) => {
    return {
        type: MENUS_LIST,
        payload: menusList
    }
}

export const setCombinedResponse = (combinedResponse) => {
    return {
        type: SET_COMBINED_RESPONSE,
        payload: combinedResponse
    }
}