import { IS_LOADING_DATA, LOADER,LINE_CHART_LOADER, IS_LOADING_ATTACKS_BLOCKED, 
  REFRESH_PAGE, USER_REQUEST_STATS_LOADER, ATTACKS_INFORMATION_LOADER, ATTACK_TRENDLINE_LOADER, 
  ATTACKS_BY_CATEGORY_LOADER, ATTACKS_BY_SEVERITY_LOADER, GEO_LOCATION_LOADER, 
  HITS_AND_ATTACKS_LOADER, DOWNLOAD_PDF, SET_COMBINED_LOADER } from "../../constants/actions";

// Action
export const setLoader = (loading) => {
    return {
        type: LOADER,
        payload: loading,
    };
};

// Action
export const setIsRefresh = () => {
    return {
        type: REFRESH_PAGE,
    };
};

export const setLoadingData = (loadingData) => {
    return {
        type: IS_LOADING_DATA,
        payload: loadingData,
    };
};



export const setIsLoadingLineChart = (isLoadingLineChart) => {
    return {
        type: LINE_CHART_LOADER,
        payload: isLoadingLineChart,
    };
};

export const setIsLoadingAttacksBlocked = (isLoading) => {
    return {
        type: IS_LOADING_ATTACKS_BLOCKED,
        payload: isLoading,
    };
};


export const setHitsAndAttacksLoader = (loading) => ({
    type: HITS_AND_ATTACKS_LOADER,
    payload: loading,
  });
  
  export const setGeoLocationLoader = (loading) => ({
    type: GEO_LOCATION_LOADER,
    payload: loading,
  });
  
  export const setAttacksBySeverityLoader = (loading) => ({
    type: ATTACKS_BY_SEVERITY_LOADER,
    payload: loading,
  });
  
  export const setAttacksByCategoryLoader = (loading) => ({
    type: ATTACKS_BY_CATEGORY_LOADER,
    payload: loading,
  });
  
  export const setAttackTrendlineLoader = (loading) => ({
    type: ATTACK_TRENDLINE_LOADER,
    payload: loading,
  });
  
  export const setAttacksInformationLoader = (loading) => ({
    type: ATTACKS_INFORMATION_LOADER,
    payload: loading,
  });
  
  export const setUserRequestStatsLoader = (loading) => ({
    type: USER_REQUEST_STATS_LOADER,
    payload: loading,
  });

  export const setIsDownloadingPdf = (isDownloadingPdf) => ({
    type: DOWNLOAD_PDF,
    payload: isDownloadingPdf,
  });


export const setCombinedResponseLoader = (combinedLoader) => ({
  type: SET_COMBINED_LOADER,
  payload: combinedLoader,
});