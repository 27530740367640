import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useLogout } from './utils/logout';
import { makeStyles } from '@material-ui/core';

import NoDomainsImg from '../assets/icons/svg/noDomainsImg.svg'

const useStyles = makeStyles((theme) => ({
    container:{
        display:'flex',
        flexDirection:'column',
        alignItems:"center",
        paddingTop:theme.spacing(2)
        
        
    }

}))

const NoDomainsOnboarded = () => {

    const classes = useStyles()

  return (
    <Box id="noDomainsOnboardedPage" className={classes.container}>
      <img src={NoDomainsImg} alt="noDomainsImage"/>
      <Typography>Waiting for domains to get onboarded. Please try again and later.</Typography>
      <Button onClick={useLogout()} variant='outlined'>Logout</Button>
    </Box>
  );
};

export default NoDomainsOnboarded;
