import * as React from 'react';
import { Box, Button, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CreateClient from './CreateClient';
import { Delete, Edit } from '@mui/icons-material';
import { addOrEditOrDeleteClient, getAllPaginatedClients } from './clientServices';
import EditableChip from './EditableChip';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import UrlAddIcon from '../../../assets/icons/svg/link.svg'
import EditClient from './EditClient';
import { uploadFileApi } from '../../../services/UploadFile';
import { CustomButton, CustomDiv, CustomTableCell, CustomTableContainer, CustomTableHead, HeadingStyled, StyledImg, StyledMuiIcon } from '../reuseStyles';
import { CustomTablePagination } from '../adminStyles';
import SendIcon from '@mui/icons-material/Send';
import SendReport from './SendReport';
import { pushNotification } from '../../../redux/notification/notificationActions';
import AddDomainToClient from './AddDomainToClient';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteConfirmationDialog from '../../../components/common/customDeleteBox/DeleteConfirmationDialog';

const headers = [
    {id:"clientName",label:"Client Name",padding:""},
    {id:"businessOwner",label:"Business Owner",padding:""},
    {id:"boEmail",label:"B.O Email",padding:""},
    {id:"boPhone",label:"B.O Phone",padding:""},
    {id:"technicalOwner",label:"Technical Owner",padding:""},
    {id:"toEmail",label:"T.O Email",padding:""},
    {id:"toPhone",label:"T.O Phone",padding:""},
    // {id:"recommedations",label:"Recommedations",padding:""},
    {id:"actions",label:"Actions",padding:6}
];

const useStyles = makeStyles((theme) => ({
  tmTableHeading: {
    marginTop: "10px",
  },
  ellipsisCell: {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const AllClientsList = () => {
  const [clientsList, setClientsList] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isCreateClient, setIsCreateClient] = useState(false);
  const [isEditClient, setIsEditClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // console.log("clientsList ",clientsList)

  const [deleteClientId, setDeleteClientId] = useState<string | null>(null); // State to manage the delete confirmation dialog

  // const [sendReportTo, setSendReportTo] = useState("");

    // const handleAddDomainBtnClick = (event) => {
    //   setAnchorEl(event?.currentTarget);
    // };

  //   const handleCloseDomainPopover = () => {
  //     setAnchorEl(null)
  //   };



  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
  const dispatch = useDispatch();
  
  useEffect(() => {
    handleGetPaginatedClients();
  }, [searchTerm, page, rowsPerPage]);
 
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleGetPaginatedClients = async (clientId="") => {
    
    const searchByValue = clientId ? 'client_id' : 'client_name';
    const searchTermValue = clientId || searchTerm;
  
    const res: any = await getAllPaginatedClients(page + 1, rowsPerPage,searchByValue, searchTermValue);

    const clientList = res?.data?.clients?.map(item => ({
      business_owner_email: item?.business_owner_email || "-",
      business_owner_phone: item?.business_owner_phone || "-",
      business_owner: item?.business_owner || "-",
      technical_owner_email: item?.technical_owner_email || "-",
      technical_owner_phone: item?.technical_owner_phone || "-",
      technical_owner: item?.technical_owner || "-",
      file_name: item?.file_name,
      client_id: item?.client_id,
      client_name: item?.client_name,
      domains_data: item?.domains_data || [],
      address:item?.address,
      gstin:item?.gstin,
      pan:item?.pan

    }));

    if (clientId) {
      // If a specific client was updated, only update that client in the list
      setClientsList(prevList => 
        prevList?.map(client => 
          client?.client_id === clientId ? res?.data.clients[0] : client
        )
      );
    } 
    else {
      // If no specific client, update the whole list
      setClientsList(clientList?.sort((a, b) => b?.clientAccId - a?.clientAccId));
      setMessage(res?.message);
      setTotalRecords(res?.data?.total_records);
    }

   
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteClient = async () => {
    if (!deleteClientId) return;

    const response: any = await addOrEditOrDeleteClient("delete", deleteClientId, null);
    
    if (response?.success) {
        dispatch(pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.SUCCESS,
        }));
        handleGetPaginatedClients();
    } else {
        dispatch(pushNotification({
            isOpen: true,
            message: "Failed to delete the client",
            type: CONSTANTS.ERROR,
        }));
    }
    setDeleteClientId(null);
};


  // const handleDeleteClient = async (clientId: string) => {
  //   const response: any = await addOrEditOrDeleteClient("delete", clientId, null);
    
  //   if (response?.success) {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: response?.message,
  //         type: CONSTANTS.SUCCESS,
  //       })
  //     );
  //     handleGetPaginatedClients();
  //   } else {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: "Failed to delete the client",
  //         type: CONSTANTS.ERROR,
  //       })
  //     );
  //   }
  // };

  const handleEditClient = (client: any) => {
    setIsEditClient(true);
    setSelectedClient(client);
  };

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const fileResponse = await uploadFileApi(file.name, file, "cyberwasp/recommendations", {});

    if (fileResponse?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: fileResponse.message,
          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: "Upload file failed",
          type: CONSTANTS.ERROR,
        })
      );
    }

    const dataWithFile = { recommendations: fileResponse?.data?.id, file_name: file?.name };
    const response = await addOrEditOrDeleteClient("update", row?.client_id, dataWithFile);
    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      handleGetPaginatedClients();
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: "Only json files are allowed. Please check the file extension",
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const ExpandableTableRow = ({ rowData, children, ...otherProps }: any) => {

    const isExpanded = expandedRowId === rowData?.client_id;


    const handleExpand = () => {
      setExpandedRowId(isExpanded ? null : rowData?.client_id);
    };
  
    const StyledDeleteIcon = StyledMuiIcon(Delete);
    const StyledEditIcon = StyledMuiIcon(Edit);
  
    return (
      <>
        <TableRow {...otherProps}>
          {children}
          <TableCell sx={{ display: "flex" }}>
            {/* <IconButton title="Delete" onClick={() => handleDeleteClient(rowData?.client_id)}>
              <StyledDeleteIcon sx={{ color: "red" }} />
            </IconButton> */}
             <IconButton title="Delete" onClick={() => setDeleteClientId(rowData?.client_id)}>
              <StyledDeleteIcon sx={{ color: "red" }} />
            </IconButton>
            <IconButton title="Edit" onClick={() => handleEditClient(rowData)}>
              <StyledEditIcon sx={{ color: "blue" }} />
            </IconButton>
            <IconButton title={isExpanded ? "Close Urls Window" : "Add Urls"} onClick={handleExpand}>
              <StyledImg src={UrlAddIcon} />
            </IconButton>
            {/* <IconButton title="Send Report" onClick={() => setSendReportTo(rowData.client_id)}>
              <SendIcon sx={{ color: "blue" }} />
            </IconButton> */}
          </TableCell>
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell colSpan={10}>
              <DomainsCollapsible domains={rowData?.domains_data} clientId={rowData?.client_id}/>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };
  
  const DomainsCollapsible = ({ domains,clientId }: { domains: any[],clientId:string }) => {
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleAddDomainBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseDomainPopover = () => {
      setAnchorEl(null);
    };

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, justifyContent: "flex-end", alignItems: "center" }}>
        {domains?.length > 0 ? (
          domains?.map((item) => (
            <EditableChip
              key={item?.domain_id}
              domainInfo={item}
              domain={item?.domain_name}
              domainId={item?.domain_id}
              clientId={clientId}
              modeValue={item?.current_mode}
              selectMode={false}
              handleGetPaginatedClients={() => handleGetPaginatedClients(clientId)}
              setSelectMode={() => {}}
            />
          ))
        ) : (
          <Typography>No domains added</Typography>
        )}
         {/* <IconButton onClick={handleAddDomainBtnClick}>
           <AddCircleOutlineIcon />
         </IconButton> */}
        <AddDomainToClient 
          clientId={clientId}
          handleGetPaginatedClients={() => handleGetPaginatedClients(clientId)} 
          handleAddDomainBtnClick={handleAddDomainBtnClick}
          // onClose={handleCloseDomainPopover} 
          anchorEl={anchorEl}
        /> 
      </Box>
    );
  };

  const renderTable = () => {
    return (
      <Box>
        <CustomTableContainer sx={{ width: "100%", overflowX:'auto',overflowY: "auto" }}>
          <Table sx={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow hover>
                {headers?.map((header) => (
                  <CustomTableHead
                    sx={{
                      paddingLeft: header.padding,
                      fontWeight: 600,
                      fontStyle: "normal",
                      width: "40%"
                    }}
                    key={header?.id}
                  >
                    {header?.label}
                  </CustomTableHead>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsList && clientsList?.map((row) => (
                <ExpandableTableRow
                  hover
                  rowData={row}
                  key={row?.client_id}
                  expandedRowId={expandedRowId}
                  setExpandedRowId={setExpandedRowId}
                >
                  <CustomTableCell title={row?.client_name}>{row?.client_name}</CustomTableCell>
                  <CustomTableCell title={row?.business_owner}>{row?.business_owner}</CustomTableCell>
                  <CustomTableCell title={row?.business_owner_email}>{row?.business_owner_email}</CustomTableCell>
                  <CustomTableCell title={row?.business_owner_phone}>{row?.business_owner_phone}</CustomTableCell>
                  <CustomTableCell title={row?.technical_owner}>{row?.technical_owner}</CustomTableCell>
                  <CustomTableCell title={row?.technical_owner_email}>{row?.technical_owner_email}</CustomTableCell>
                  <CustomTableCell title={row?.technical_owner_phone}>{row?.technical_owner_phone}</CustomTableCell>
                  {/* <CustomTableCell align="center">
                    <input
                      type="file"
                      id={`fileInput-${row.client_id}`}
                      onChange={(event) => handleFile(event, row)}
                    />
                    <label style={{ display: "flex", justifyContent: "center", alignItems: "center" }} htmlFor={`fileInput-${row.client_id}`}>
                      {row?.file_name && <Stack fontSize={"0.8rem"}>{row?.file_name}</Stack>}
                    </label>
                  </CustomTableCell> */}
                </ExpandableTableRow>
              ))}
              {message === "" && clientsList?.length === 0 && (
                <TableRow>
                  <CustomTableCell>Please wait...</CustomTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CustomTableContainer>
        <Paper sx={{ width: "100%", flexGrow: 1, mt: "0.5%", pb: 0, display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
          <CustomTablePagination
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ p: "4%", overflowX:"auto", height: `calc(100vh - 80px)` }} width={"100%"}>
      {/* <Modal
        open={sendReportTo !== ""}
        onClose={() => {
          setSendReportTo("");
        }}
      >
        <Box sx={{
          overflow: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#FFFFFF",
        }}>
          <SendReport clientId={sendReportTo} setSendReportTo={setSendReportTo} />
        </Box>
      </Modal> */}
      <Modal
        open={isCreateClient}
        onClose={() => {
          setIsCreateClient(false);
        }}
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#FFFFFF",
        }}>
          <CreateClient 
            setShow={setIsCreateClient} 
            handleGetAllClients={handleGetPaginatedClients}
          />
        </Box>
      </Modal>
      <Modal
        open={isEditClient}
        onClose={() => {
          setIsEditClient(false);
        }}
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#FFFFFF",
        }}>
          <EditClient 
            selectedClient={selectedClient} 
            setShow={setIsEditClient}
            handleGetAllClients={handleGetPaginatedClients}
          />
        </Box>
      </Modal>
      {deleteClientId && (
          <DeleteConfirmationDialog
              open={Boolean(deleteClientId)}
              onClose={() => setDeleteClientId(null)}
              onConfirm={handleDeleteClient}
          />
      )}
      <HeadingStyled color="rgb(5, 136, 211)" variant='h4'>Clients</HeadingStyled>
      <Box display={"flex"} flexDirection={"row"} mt={"1rem"} mb={"2rem"}>
        <TextField
          type={"search"}
          value={searchTerm}
          placeholder={"search"}
          sx={{ 
            width: "18%",
            minWidth: "10em",
            justifyContent: "flex-start",
            ".MuiInputBase-input": {
              height: { lg: "1.5rem", xl: "1.8rem" }
            }
          }}
          size={"small"}
          onChange={handleSearch}
        />
        <CustomButton
          sx={{ 
            lineHeight: "100%",
            justifyContent: "flex-end",
            ml: "auto",
          }}
          variant={"contained"}
          onClick={() => setIsCreateClient(true)}
        >
          Add Client
        </CustomButton>
      </Box>
      {renderTable()}
    </Box>
  );
};

export default AllClientsList;
