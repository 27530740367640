import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../screen/dashboard";
import NoPageFound from "../../screen/NoPageFound";
import ProfilePage from "../../screen/profile";
import AllClientsList from "../../screen/WafAdmin/Client/ClientList";
import Users from "../../screen/WafAdmin/User/Users";
import Support from "../../screen/support";
import NoDomainsOnboarded from "../../screen/NoDomainsOnboarded";
import Logout from "../../screen/Logout";
import EulaWrapper from "../eula/EulaWrapper";
import CONSTANTS from "../../constants/constants";

const Navigator = () => {
  // const { isWafAdmin } = useSelector((state: any) =>  state.authentication );
  
  return (
    <Routes>
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`} element={<Dashboard/>} />
      
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}user-profile`} element={<ProfilePage/>} />
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}support`} element={<Support />} />
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}users`} element={<Users />} />

      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}clients`} element={<AllClientsList />} />
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${process.env.REACT_APP_REALM}`} element={<Logout/>} />
      {/* <Route path={`${process.env.PUBLIC_URL}/no-domains-onboarded`} element={<NoDomainsOnboarded/>} /> */}
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}*`} element={<NoPageFound />} />


    </Routes>
  );
};

export default Navigator;
