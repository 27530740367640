import CONSTANTS from "../constants/constants"
import { USER_PROFILE_ENDPOINT } from "../constants/endpoints"
import { setUserPreferences, setUserProfileData } from "../redux/profile/profileActions"
import { getUserPreferences } from "../screen/WafAdmin/User/userService"
import { request } from "./request"

export const userProfilePageApi = async (dispatch)=>{


    try{
        const response:any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${USER_PROFILE_ENDPOINT}${sessionStorage.getItem(CONSTANTS.AWGMENT_USER_ID)}`)
    
        dispatch(setUserProfileData(response))

    }
    catch(error){
        console.log("error ",error)
    }
    
}


export const getUserProfileImage  = async (dispatch) =>{
   const response = await getUserPreferences(dispatch,setUserPreferences);
    dispatch(setUserPreferences(response?.data))

}
