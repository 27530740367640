import React from 'react';
import {
  Container,
  Typography,
  Divider,
  Box,
  TextField,
  Autocomplete,
  Pagination
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

import ReusableListComponent from './ReusableListComponent';


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius:'0.5rem',
    [theme.breakpoints.down('md')]: {
      width:'100%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    alignItems: 'center',

  },
  count: {
    color: '#0A0A0A',
  },
  scrollableContent: {
    height: 340, // Adjust height as needed
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#0046FF',
      borderRadius: '8px', // Rounded borders for thumb
    },
    '&::-webkit-scrollbar-track': {
      background: '#CCCCCC',
      backgroundClip: 'padding-box', // Prevent the border from increasing the thumb's size
      borderRadius: '8px', // Rounded borders for track
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
  }
}));

const AttacksByCategoryComponent = ({attacksByCategory}) => {
  const classes = useStyles();

  return (
    <ReusableListComponent
      id="attackCategories"
      data={attacksByCategory}
      leftColumnHeader="Attack Category"
      rightColumnHeader="Count"
      filterLabel="Filter by Category"
      width="100%" title={"Attack Categories"}
      showLegendDot = {true}
      showSequence = {false}    
      legendDotColor={'#FB5607'}
      />

  )
    

};

export default AttacksByCategoryComponent;
