// import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { Chip, TextField, IconButton } from '@mui/material';
// import { Save, Cancel, Delete } from '@mui/icons-material';
// import { deleteUrlFromClient, editUrlInClient } from './clientServices';
// import CONSTANTS from '../../../constants/constants';
// import { useDispatch } from 'react-redux';
// import { StyledMuiIcon } from '../reuseStyles';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';
// import ToggleOffIcon from '@mui/icons-material/ToggleOff';
// import { pushNotification } from '../../../redux/notification/notificationActions';


// interface Props{
//     domain:string;
//     domainId:number;
//     handleGetAllurls?:any;
//     modeValue?:string;
//     selectMode?:any;
//     domainInfo?:any;
//     setSelectMode?:any;
//     handleGetPaginatedClients?:any
// }



// const EditableChip = ({domain,domainId,handleGetAllurls,modeValue,
//   selectMode,setSelectMode,
//   handleGetPaginatedClients
// }:Props) => {
//   const [editMode, setEditMode] = useState(false);
//   const [chipValue, setChipValue] = useState('Editable Chip');
//   const [tempValue, setTempValue] = useState('');
//   // const [selectMode,setSelectMode] = useState(false)
//   const dispatch = useDispatch()

//   const handleChipClick = () => {
//     setTempValue(domain);
//     setEditMode(true);
//   };

//   const handleInputChange = (event) => {
//     const inputValue = event.target.value;
//     // Regular expression to check for a valid website URL pattern

//       setTempValue(inputValue);
      
//   };


//   const handleToggle = async() => {
//     const domainMode = selectMode?"blocking":"detectionOnly"

//     // console.log("select mode ",selectMode)
//     // console.log("domainmode",domainMode)

//     // console.log("domainId,selectMode: domainmode,mode",domainId,selectMode,domainMode,modeValue) 
     
//     const response:any = await editUrlInClient(domainId,{"mode":domainMode},dispatch)

//       if (response?.success) {
//         dispatch(
//         pushNotification({
//             isOpen: true,
//             message: response.message,
//             type: CONSTANTS.SUCCESS,
//         })
//         );
//         handleGetAllurls()
//     } else {
        
//         dispatch(
//         pushNotification({
//             isOpen: true,
//             message: response.error,
//             type: CONSTANTS.ERROR,
//         })
//         );
//     }

//   }


//   const handleAvatarClick = (e) => {

//     e.stopPropagation(); // Prevent the Chip click event from triggering
//     setSelectMode(!selectMode)
//     handleToggle(); // Toggle the selected state when the avatar is clicked
//   };

//   const handleSaveClick = async() => {
//     setChipValue(tempValue);
//     setEditMode(false);


//     const response:any = await editUrlInClient(domainId,{domain_name:tempValue},dispatch)

//     if (response?.success) {
//       dispatch(
//       pushNotification({
//           isOpen: true,
//           message: response.message,
//           type: CONSTANTS.SUCCESS,
//       })
//       );
//       handleGetAllurls()
//   } else {
      
//       dispatch(
//       pushNotification({
//           isOpen: true,
//           message: response.error,
//           type: CONSTANTS.ERROR,
//       })
//       );
//   }
    
//   };

//   const handleCancelClick = () => {
//     setEditMode(false);
//   };

//   const handleDeleteClick = async() => {


//     const response:any = await deleteUrlFromClient(domainId)

//     if (response?.success) {
//       dispatch(
//       pushNotification({
//           isOpen: true,
//           message: response.message,
//           type: CONSTANTS.SUCCESS,
//       })
//       );
//       handleGetPaginatedClients()
//   } else {
      
//       dispatch(
//       pushNotification({
//           isOpen: true,
//           message: response.error,
//           type: CONSTANTS.ERROR,
//       })
//       );
//   }

//   };

// const SaveIcon = StyledMuiIcon(Save)
// const CancelIcon = StyledMuiIcon(Cancel)
// const toggleIcon = modeValue == "blocking" ? <ToggleOnIcon color='primary'/> : <ToggleOffIcon />;

//   return (
//     <div>
//       {editMode ? (
//         <div>
//           <TextField
//             value={tempValue}
//             onChange={handleInputChange}
//             autoFocus
//           />
//           <IconButton onClick={handleSaveClick}>
//             <SaveIcon sx={{color:"green !important"}} />
//           </IconButton>
//           <IconButton onClick={handleCancelClick}>
//             <CancelIcon/>
//           </IconButton>
//         </div>
//       ) : (
//         <Chip
//           sx={{
//             width:"100%",
//             display:"flex",
//             justifyContent:"space-between",
//             mr:"2rem",
//             p:{xs:"0.7rem",lg:"0.9rem",xl:"1.1rem"},
//             backgroundColor:"lightblue",
//             '& .MuiChip-label':{
//               fontSize:{xs:"0.7rem",lg:"0.9rem",xl:"1.1rem"}
//             }
//           }}
//           label={domain}
//           onClick={handleChipClick}
//           variant="outlined"
//           onDelete={handleDeleteClick}
//           deleteIcon={<Delete sx={{color:"gray !important",fontSize:"1.4rem !important"}} />}
//           // avatar={<div onClick={handleAvatarClick}>{toggleIcon}</div>}
//         />
        
//       )}
//     </div>
//   );
// };

// export default EditableChip;



import React, { useState } from 'react';
import { Chip, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import CONSTANTS from '../../../constants/constants';
import { deleteUrlFromClient } from './clientServices';
import { pushNotification } from '../../../redux/notification/notificationActions';
import AddDomainToClient from './AddDomainToClient';
import EditDomain from './EditDomain';
import EditForm from './EditDomain';
import DeleteConfirmationDialog from '../../../components/common/customDeleteBox/DeleteConfirmationDialog';

interface Props {
  domain: string;
  domainId: string;
  clientId?:any;
  domainInfo?:any;
  handleGetAllurls?: () => void;
  modeValue?: string;
  selectMode?: boolean;
  setSelectMode?: (mode: boolean) => void;
  handleGetPaginatedClients?: () => void;
}

const EditableChip: React.FC<Props> = ({
  domain,
  domainId,
  clientId,
  domainInfo,
  handleGetAllurls,
  modeValue,
  selectMode,
  setSelectMode,
  handleGetPaginatedClients
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDomainDelete,setIsDomainDelete] = useState<boolean>(false)
  const dispatch = useDispatch();

  // console.log("clientid in edit chip ",clientId)
  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsEditing(true);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    setAnchorEl(null);
  };

  const handleDeleteClick = async () => {
    // console.log("delte clicked")
    const response: any = await deleteUrlFromClient(domainId);

    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      if (handleGetPaginatedClients) {
        handleGetPaginatedClients();
      }
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.error,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  return (
    <div>
      <Chip
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mr: "2rem",
          p: { xs: "0.7rem", lg: "0.9rem", xl: "1.1rem" },
          backgroundColor: "lightblue",
          '& .MuiChip-label': {
            fontSize: { xs: "0.7rem", lg: "0.9rem", xl: "1.1rem" }
          }
        }}
        label={domain}
        variant="outlined"
        onDelete={handleDeleteClick}
        deleteIcon={
          <>
            <IconButton onClick={handleEditClick} size="small">
              <Edit sx={{ color: "blue", fontSize: "1.2rem" }} />
            </IconButton>
            <IconButton onClick={()=>setIsDomainDelete(true)}>
              <Delete sx={{cursor:"pointer", color: "gray", fontSize: "1.4rem" }} />
            </IconButton>
          </>
        }
      />
       {isDomainDelete &&
          <DeleteConfirmationDialog
              title = {'Delete domain'}
              description={'Are you sure you want to delete this domain?'}
              open={isDomainDelete}
              onClose={() => setIsDomainDelete(false)}
              onConfirm={handleDeleteClick}
          />}
      {isEditing && (
        // <EditForm/>
        <EditDomain
          clientId={clientId}
          domainInfo={domainInfo}
          handleGetPaginatedClients={handleGetPaginatedClients}
          // initialValues={{
          //   ...domainInfo
          //   // Add other initial values here if available
          // }}
          isEditing={isEditing}
          onClose={handleCloseEdit}
          anchorEl={anchorEl}
        />
      )}
    </div>
  );
};

export default EditableChip;