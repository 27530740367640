import CONSTANTS from "../constants/constants";
import { MENUS } from "../constants/endpoints";
import { setIsWafAdmin } from "../redux/authentication/authActions";
import { setMenusList } from "../redux/ui/uiActions";
// import { setIsWafAdmin, setMenusList } from "../redux/actions";
import { request } from "./request";



export const getMenusApi = async (dispatch) => {

    try {
      const response:any = await request.get(`${sessionStorage.getItem(CONSTANTS.AWGMENT_API)}${MENUS}`);
      // console.log("response in menuapi ",response)
      if(response?.success){
        const isAllowed =
        response?.data?.findIndex((menu) => menu.url === "/clients") !== -1;

          // console.log("isAllowed ",isAllowed)
    
        dispatch(setIsWafAdmin(isAllowed));
        dispatch(setMenusList(response?.data));

      }

  
    } catch (error) {
      console.error("Error fetching menus:", error);
      // Handle the error here, e.g., show an error message to the user
      return false;
    }
  };