import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import DetailItems from '../../components/common/profileDetailsReusableComponent';

const useStyles = makeStyles((theme) => ({
  subscriptionDetails: {
    width: '50%',
  },
}));

interface WafSubscriptionPageProps {
  id: string;
  data: {
    plan_name: string;
    features: string[];
    cost: string;
    subscription_start_date: string;
    subscription_end_date: string;
  };
}

const WafSubscriptionPage: React.FC<WafSubscriptionPageProps> = ({ id, data }) => {
  const classes = useStyles();

  const subscriptionData = {
    'Plan name': data?.plan_name,
    'Features': data?.features,
    'Cost': data?.cost,
    'Subscription start date': data?.subscription_start_date,
    'Subscription end date': data?.subscription_end_date,
  };

  return (
    <Box id={id} className={classes.subscriptionDetails}>
      <DetailItems data={subscriptionData} />
    </Box>
  );
};

export default WafSubscriptionPage;
