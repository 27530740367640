import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Box, Icon, Grid, Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { initialStatsData } from '../../components/initial/InitialStatsData';
import { useDispatch, useSelector } from 'react-redux';
import { PlaceholderComponent } from './PlaceHolderComponent';

import { ReactComponent as ArrowDropUpIcon } from '../../assets/icons/svg/increment.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../assets/icons/svg/decrement.svg';
import '../../styles/DashboardStyles.css'

const useStyles = makeStyles((theme) => ({

  root:{
    paddingLeft:theme.spacing(1),
    paddingRight:theme.spacing(2),
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    

  },
  contentBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: theme.spacing(0.5),paddingTop:0,
    flex: 1
  },
  title: {
    fontWeight: 400,
    color: '#3D4655 !important',
    // fontSize: '0.7rem !important',
    
  },
  value: {
    fontSize: '1.7rem !important',
    color: '#3D4655',
    '@media (max-width:1025px)': {
        fontSize: '1.5rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '2.5rem !important',
     },
  },
  growthBox: {
    display: 'flex',
    alignItems: 'center',
  },
  growthColor:{
    color:'#43C702'
  },
  fallColor:{
    color: '#FD1515'

  },

  growthRateDescription:{
    fontSize:'0.85rem !important',
    marginRight:'0.2rem !important',
    marginLeft:'0.2rem !important'
  },
  description: {
    color: '#3D4655',
    fontSize: '0.75rem !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '0.85rem !important',
    },
  },
  gradientBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'linear-gradient(358.9deg, #0046FF -6.03%, #0095FF 144.98%)',
    borderRadius: '0.5rem',
  },

}))


const StatCard = ({ title, value, description, icon: Icon, growthRate,loading }) => {
  const isPositive = growthRate >= 0;
  const classes = useStyles();

  return (
    <>
      <Box  className={classes.root}>
        <Box className={classes.contentBox}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Typography fontWeight={600} className={classes.value} variant="h2">
            {loading?<div className="dots_loader"></div>:value}
          </Typography>
            {/* <Box className={classes.growthBox}>
              {isPositive ? (
                <ArrowDropUpIcon/>
              ) : (
                <ArrowDropDownIcon />
              )}
              <Typography
                variant="body2"
                component="span"
                fontWeight={400}
                className={classes.growthRateDescription}
              >
                {`${isPositive ? '+' : ''}${growthRate}%`}
              </Typography>
              <Typography fontWeight={400} className={classes.description} variant="body1">
                {description}
              </Typography>
            </Box> */}
        </Box>
        <Box className={classes.gradientBox}>
          <Icon />
        </Box>
      </Box>
      
    </>
  );
};

const StatsCards = ({id,statsData}) => {
  
  // const {statsData} = useSelector((state: any) => state?.ui);

  const dispatch = useDispatch()

  return (
    <Grid id={id} container spacing={2}>
    {statsData?.filter(stat =>stat?.id !== 'attack_percentage')?.map((stat, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <PlaceholderComponent id={`${id}-placeHolder`} text={""} marginBottom={0} padding={0} height={{ xs: 100, md: 120, xl: 140 }}>
          <StatCard
                title={stat?.title}
                value={stat?.value}
                // value={stat?.id === 'bandwidth'?(stat?.value)?.toFixed(4):stat?.value}
                description={stat?.description}
                icon={stat?.icon}
                growthRate={stat?.growthRate}
                loading={stat?.isLoading}
              />

        </PlaceholderComponent>
      </Grid>
    ))}
  </Grid>
   
  );
};

export default StatsCards;


