import { Box } from '@mui/material'
import React from 'react'

const Support = () => {
  return (
    <Box display={'flex'} pt={5} justifyContent={'center'} height={'100%'}>Coming soon...</Box>
  )
}

export default Support
