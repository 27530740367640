import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, TextFieldProps, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';

const useStyles = makeStyles((theme) => ({
  selectField: {
    '& .MuiInputBase-root': {
      fontSize:'0.9rem',
      color: (props: {height,color?: string }) => props.color || '#6E7079',
      height: (props: {height?: string }) => props.height || 45,
      backgroundColor:'#FFFFFF',
      [theme.breakpoints.up('xs')]: {
        fontSize:'0.9rem !important',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize:'0.95rem !important',
      },
      [theme.breakpoints.up('md')]: {
        fontSize:'0.9rem !important',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize:'1rem !important',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize:'1.2rem !important',
      },
      '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
      height: (props: {height?: string }) => 50,

      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem !important',
        height: (props: {height?: string }) => 55,

      },
    },
  },

 
  textField: {
    borderRadius: '0.5rem !important',
    border: '1px solid #0046FF !important',
  },
  selectIcon: {
    right: 12,
    margin: -4,
  },
  menuItem:{
    fontSize:'1rem !important',
    color:'#696969 !important',
    [theme.breakpoints.up('xs')]: {
      fontSize:'0.9rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize:'0.95rem !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize:'0.9rem !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize:'1rem !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize:'1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  },
  activeMenuItem: {
    backgroundColor: '#0046FF !important', // Light blue background
    color: '#FFFFFF !important', // White text
  },
}));

interface CustomSelectProps extends Omit<TextFieldProps, 'variant'> {
  id: string;
  options: Array<{ value: string; label: string }>;
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  variant?: 'outlined' | 'filled' | 'standard';
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  additionalProps?: object;
  height?: any;
  width?: string; // Add width prop
  label?:string;
  color?:any;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  id,
  options,
  value,
  onChange,
  startAdornment,
  endAdornment,
  label,
  height,color,
  variant = 'outlined',
  size = 'medium',
  fullWidth = true,
  additionalProps = {},
  width="100%", // Destructure width prop
}) => {
  const classes = useStyles({ height,color });

  return (
    <TextField
      id={id}
      select
      size={size}
      value={value}
      onChange={onChange}
      variant={variant}
      fullWidth={fullWidth}
      SelectProps={{
        IconComponent: DownArrow,
        classes: { icon: classes.selectIcon },
        // MenuProps: {
        //   PaperProps: {
        //     sx: {
        //       color:color?color:'#6E7079',
        //     },
        //   },
        // },
      }}
      className={classes.selectField}
      InputProps={{
        classes: { notchedOutline: classes.textField },
        startAdornment: startAdornment,
        endAdornment: endAdornment,
      }}
      sx={{ width}} // Apply the width dynamically
      {...additionalProps}
    >
      {options?.map((option) => {
        return (
          <MenuItem 
            classes={{
              root: classes.menuItem,
              selected: classes.activeMenuItem,
            }}
            className={classes.menuItem}
            id={`${id}-menuItem`} 
            key={option?.value} 
            value={option?.value}>
            {option?.label}
          </MenuItem>
          
        )
      })}
    </TextField>
  );
};

export default CustomSelect;
