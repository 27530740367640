import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Box,
  Checkbox,
  Typography,
  Chip,
  Button,
  styled,
  FormControlLabel,
  InputAdornment
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as ViewMoreInfoIcon } from '../../../assets/icons/svg/view_more.svg';
import ViewMoreDetailsDrawer from './ViewMoreDetailsDrawer';
import { viewMoreAttackInfoApi } from '../../../services/dashboardApis';
import CustomPagination from '../../../components/common/customPagination';

import { ReactComponent as DownloadIcon } from '../../../assets/icons/svg/downloadIcon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/svg/filterIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/svg/Search.svg';
import { getSeverityColor } from '../../utils/getColor';
import CustomDate from '../../../components/common/customDate';
import NoAttacksFound from '../../NoAttacksFound';
import ClearIcon from '@mui/icons-material/Clear'; // Import Clear Icon
import FilterPopover from '../../../components/common/customFilter/FilterPopOver';
import { useSelector } from 'react-redux';
import { formatDateWithAMPM } from '../../utils/convertTime';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '98%',
    overflow: 'hidden',
    height: '100%',
    padding:'0.5rem 1rem 0.5rem 1.5rem'

  },
  tableContainer: {
    width:'98%',
    overflow: 'auto',paddingLeft:theme.spacing(2),
    marginTop: theme.spacing(1.5),
    '&::-webkit-scrollbar': {
      width: '0rem',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#2196f3',
      borderRadius: '100px',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track': {
      background: '#CCCCCC',
      borderRadius: '100px',
      border: '0.5rem solid #CCCCCC',
    },
  },
  tableBodyCell: {
    color: '#6E7079 !important',
    fontSize: '1rem',
    borderBottom: 'none !important', // Remove bottom border for table body cells
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 180, // Set a maximum width for the cells
    '@media (min-width:600px)': {
        fontSize: '0.9rem !important',
      },
      '@media (min-width:960px)': {
        fontSize: '0.95rem !important',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.05rem !important',
      },
      '@media (min-width:1920px)': {
        fontSize: '1.1rem !important',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.4rem !important',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.6rem !important',
      },
  },
  tableHeadCell: {
    borderTop: '1px solid #E1E2E9',
    color: '#2C2D33 !important',
    fontSize: '1rem !important',
    '@media (min-width:600px)': {
        fontSize: '0.9rem !important',
      },
      '@media (min-width:960px)': {
        fontSize: '0.95rem !important',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.05rem !important',
      },
      '@media (min-width:1920px)': {
        fontSize: '1.1rem !important',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.4rem !important',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.6rem !important',
      },
  },
  TitleAndFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchInput: {
    marginRight: theme.spacing(1),
    width: '50%', // Set default width
    // '&:hover': {
    //   width: 300, // Adjust width on hover
    //   transition: 'width 0.3s ease-in-out', // Smooth transition
    // },
    '& .MuiOutlinedInput-root': {
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 6px',
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0,
    },

    [theme.breakpoints.up('xs')]: {
      width: '80%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },

    [theme.breakpoints.between('sm' , 'md')]: {
      width: '58%',
    },
    [theme.breakpoints.between('md' , 'lg')]: {
      width: '54%',
      
    },
    [theme.breakpoints.up('lg')]: {
      width: '52%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
    '@media (min-width:1024px)': {
      width: '60%',
    },
    '@media (min-width:1200px)': {
      width: '52%',
    },
  },
  filtersAndDownloadBox: {
    width: '45%',
    display: 'flex',
    alignItems: 'center',gap:'0.6rem',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xs')]: {
      width: '80%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '52%',
    },

    [theme.breakpoints.between('sm' , 'md')]: {
      width: '53%',
    },
    [theme.breakpoints.between('md' , 'lg')]: {
      width: '50%',
      
    },
    [theme.breakpoints.up('lg')]: {
      width: '44%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },

    '@media (min-width:1024px)': {
      width: '54%',
    },
    '@media (min-width:1200px)': {
      width: '45%',
    },
    '@media (min-width:1800px)': {
      width: '40%',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    border: '1px solid #53545C !important',
    color: '#53545C !important',
    fontSize: '0.8rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.2rem !important',
      width:'20%',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.5rem !important',
    },
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      width: '2rem',  // Adjust size of the SVG icon
      height: '2rem',
      borderRadius: '1rem',
      border: '1px solid #CFD3D4',
      backgroundColor: '#fff',  // Background color of the SVG icon
      color: 'red',  // Color of the SVG icon
    },
    '& .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root': {
      backgroundColor: 'red',  // Background color when checked
      color: 'green',  // Color when checked
      borderRadius: '1rem',  // Adjust border radius of the SVG icon

    },
    '& .MuiCheckbox-root:hover .MuiSvgIcon-root': {
      backgroundColor: 'blue',  // Background color on hover
    },
  },
  typography: {
    paddingLeft: theme.spacing(2),
    flex: 1,
    color: '#1B1B29 !important',
    fontSize: '1.12rem',
   
  },
  chip: {
    background: '#FF4B5529 !important',
    color: '#FF4B55 !important',
    marginLeft: theme.spacing(1),
    '@media (min-width:2560px)': {
      fontSize: '1.2rem !important',
      padding:'1rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.4rem !important',
      padding:'1.1rem !important',
    },
  },
  tableBox: {
    height: '86%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  paginationBox: {
    alignSelf: 'center'
  },
  noDataBox: {
    height:'80%',display:'flex',
    alignItems:'center',
    justifyContent:'center'
    
  },
  chipSeverity: {
    color: '#FFFFFF !important',
    fontSize:'1rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.2rem !important',
      padding:'1rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.4rem !important',
      padding:'1.1rem !important',
    },
  },
  viewDetailsIcon: {
    color: '#0C87F0',
    fontWeight: 500,
    fontSize: '0.9rem',
    marginRight: '0.4rem',
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.6rem !important',
    },
  },
  iconButton: {
    color: '#0C87F0'
  }
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  '&.MuiCheckbox-root': {
    padding: theme.spacing(1),

  },
  '& .MuiSvgIcon-root': {
    display: 'none',

  },
  '&::before': {
    content: '""',
    display: 'block',
    width: '1.5rem',
    height: '1.5rem',
    border: '1px solid #CFD3D4',
    borderRadius: '0.4rem',
    backgroundColor: 'transparent',
  },
  '&.Mui-checked': {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      height: '50%',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='blue' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
  },
}));

const columns = [
  // { id: 'unique_id', label: 'Transaction ID', align: 'left' },
  { id: 'serialNumber', label: 'S.No', align: 'center' },
  { id: 'timestamp', label: 'Event Time (IST)', align: 'center' },
  { id: 'remote_address', label: 'Source IP', align: 'center' },
  { id: 'country_name', label: 'Country', align: 'left' },
  { id: 'uri', label: 'URI', align: 'center' },
  { id: 'severity', label: 'Severity', align: 'center' },
  { id: 'more_info', label: 'More Info', align: 'center' },
];


interface CustomButtonProps {
  id:string;
  variant:any;
  className:string;
  startIcon:any;
  children:any;
  onClick:any;
  disabled?:boolean; 
}

const CustomButton:React.FC<CustomButtonProps> = ({ id,variant, className, startIcon, children, onClick,disabled }) => {
  return (
    <Button
      id={id}
      sx={{fontSize:'0.8rem',fontWeight:500,cursor:'pointer'}}
      variant={variant}
      className={className}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
    
    >
      {children}
    </Button>
  );
};


const AttackInformationTable = ({id, data, totalRecords, totalPages, page, 
    setPage,pageSize, searchInput,setSearchInput,searchTerm,
    setSearchTerm, domainId,startAndEndDateTime,
    severity, setSeverity,countries, setCountries }) => {
  const [selected, setSelected] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const {attacksInformationLoading} = useSelector((state: any) => state.loaders);

  const [anchorEl, setAnchorEl] = useState(null);

  const { isDownloadingPdf } = useSelector((state: any) => state.loaders);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleClickFilters = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setFilterAnchorEl(null);
  };

  const isFitlerOpen = Boolean(filterAnchorEl);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Pagination component is 1-based but our page state is 0-based
  };

  const handleClickViewDetails = async (rowId) => {
    const rowData: any = await viewMoreAttackInfoApi(domainId, rowId,startAndEndDateTime);
    setSelectedRow(rowData);
    setDrawerOpen(true);
  };

  // const filteredData = data?.filter(row =>
  //   Object?.values(row)?.some(value =>
  //     value?.toString().toLowerCase()?.includes(searchTerm?.toLowerCase())
  //   )
  // );

  // const handleSearchKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     setSearchTerm(searchInput);
  //   }
  // };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      setPage(1)
    }
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => n.unique_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  const classes = useStyles();

  const handleClickDownloadBtn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchClick = () => {
    setSearchTerm(searchInput);
  };

    // Function to clear search input
    const handleClearInput = () => {
      setSearchInput('');
      setSearchTerm(''); // Clear the search term as well if needed
    };

  return (
    <Box id={id} className={classes.mainContainer}>
      <div id={`${id}-titleContainer`} className={classes.TitleAndFilterContainer}>
        <Typography className={classes.typography} fontWeight={500} variant="h2">
          Attack Information
          <Chip label="Blocked" size="small" className={classes.chip} />
        </Typography>
        <Box className={classes.filtersAndDownloadBox}>
          
          <TextField
            // onKeyPress={handleSearchKeyPress}
            onKeyDown={handleKeyDown}
            value={searchInput}
            onChange={(e)=>setSearchInput(e?.target?.value)}
            className={classes.searchInput}
            variant="outlined"
            size="small"
            placeholder="Search by Source IP or URI"
            
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleSearchClick}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchInput && (
                    <IconButton onClick={handleClearInput}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
         <CustomButton
            id={'filterIconBtn'}
            variant="outlined"
            className={classes.button}
            startIcon={<FilterIcon />}
            onClick={handleClickFilters}
          >
            Filter
          </CustomButton>
          <CustomButton
            id={'downloadIconBtn'}
            variant="outlined"
            className={classes.button}
            startIcon={<DownloadIcon />}
            onClick={handleClickDownloadBtn}
            disabled={isDownloadingPdf}
            >
            { isDownloadingPdf?<div className='dots_loader'></div>:"Download"}
          </CustomButton>
        </Box>
      </div>

      {attacksInformationLoading?
      <Box 
        id={id} 
        sx={{display:'flex',justifyContent:'center',alignItems:'center',
          backgroundColor:'transparent',
          height:'90%',
          borderRadius:'0.5rem', 
          overflow: 'hidden' }}>
          <div className="refresh-data-loader"></div>
          
      </Box>:(totalRecords > 0 && data?.length >0) ? (
        <Box className={classes.tableBox}>
          <TableContainer className={classes.tableContainer} sx={{ maxHeight: 380 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((headCell:any, index) => (
                    <TableCell
                      key={index}
                      align={headCell?.align || 'left'}
                      className={classes.tableHeadCell}
                    >
                      {headCell?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  const isItemSelected = isSelected(row?.unique_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?.unique_id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell
                        title={row?.unique_id}
                        className={classes.tableBodyCell}
                        component="th" id={labelId} scope="row">
                        {row?.unique_id}
                      </TableCell> */}

                      <TableCell align="center" className={classes.tableBodyCell}>
                        {index + 1 + ((page-1) * pageSize)}
                      </TableCell>
                      <TableCell align="center" title={formatDateWithAMPM(row?.timestamp)} className={classes.tableBodyCell}>{formatDateWithAMPM(row?.timestamp)}</TableCell>
                      <TableCell title={row?.remote_address} align="center" className={classes.tableBodyCell}>{row?.remote_address}</TableCell>
                      <TableCell
                        title={row?.country_name}
                        className={classes.tableBodyCell}
                        // component="th" scope="row"
                        >
                        {row?.country_name}
                      </TableCell>
                      <TableCell align="center" title={row?.uri} className={classes.tableBodyCell}>{row?.uri}</TableCell>
                      <TableCell align="center" className={classes.tableBodyCell}>
                        <Chip label={row?.severity || 'Unknown'} sx={{ background: getSeverityColor(row?.severity) }} className={classes.chipSeverity} size="small" />
                      </TableCell>
                      <TableCell title={"click here to view more attack information"} align="center" className={classes.tableBodyCell}>
                        <IconButton className={classes.iconButton} onClick={() => handleClickViewDetails(row.unique_id)} size="small">
                          <span className={classes.viewDetailsIcon}>View Details</span>
                          <ViewMoreInfoIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.paginationBox}>
            <CustomPagination
              page={page}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
            />
          </Box>
        </Box>
      ) : (
        <div className={classes.noDataBox}>
          <NoAttacksFound id={`${id}-noAttacksInfo`}/>
        </div>
      )
      
      }


      <CustomDate 
        id="customDatePicker"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        domainId={domainId} 
        />
      <ViewMoreDetailsDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        selectedRow={selectedRow}
      />
      <FilterPopover
        open={isFitlerOpen}
        // anchorEl={filterAnchorEl}
        onClose={handleCloseFilters}
        page={page} 
        domainId={domainId} 
        startAndEndDateTime={startAndEndDateTime}
        severity={severity}
        setPage={setPage}
        setSeverity={setSeverity}
        countries={countries}
        setCountries={setCountries}
        searchTerm={searchTerm}
      />
    </Box>
  );
};

export default AttackInformationTable;
