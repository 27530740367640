import React, { useMemo, useState } from 'react';
import {
  ComposedChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Area,
  Tooltip
} from 'recharts';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { formatNumber } from '../../utils/formatNumber';
import NoAttacksFound from '../../NoAttacksFound';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { convertDateTimeString, dateSlashFormat, formatAndEncodeDate, getDaysDifference } from '../../utils/convertTime';
import { useSelector } from 'react-redux';
import { startOfHour, startOfDay, startOfMonth, startOfYear,format, add, endOfDay, endOfWeek, endOfMonth, endOfYear, endOfHour, isSameDay, isSameHour, addHours, addMonths } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    height: 350,
    [theme.breakpoints.up('sm')]: {
      height: 380,
    },
    [theme.breakpoints.up('md')]: {
      height: 440
    },
    [theme.breakpoints.up('lg')]: {
      height: 440
    },

    [theme.breakpoints.up('xl')]: {
      height: 500
    },
    [theme.breakpoints.between(900, 960)]: {
      height: 425,
    },
    [theme.breakpoints.between(1536, 1914)]: {
      height: 480,
    },
    [theme.breakpoints.between(1914, 2559)]: {
      height: 480,
    },
   
    '@media (min-width:2560px)': {
      height: 440,
    },
    
  },

  root: {
    position:'relative',
    borderRadius: '0.5rem',
    
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tooltip: {
    position: 'fixed', // Change to fixed positioning
    background: '#4A5568',
    boxShadow: '0px 1px 4px 0px #2C405A35',
    color: '#FFFFFF',
    border: '1px solid #ccc',
    padding: '10px',
    pointerEvents: 'none',
    zIndex: 1000,
    borderRadius: '4px',
    transition: 'left 0.1s, top 0.1s', // Add smooth transition
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid #4A5568',
    position: 'absolute',
    left: '50%',
    bottom: '-6px', // Position the arrow at the bottom
    transform: 'translateX(-50%)'
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  legendColorBox: {
    width: 20,
    height: 20,
    marginBottom: theme.spacing(0.2),
    marginRight: theme.spacing(1),
    borderRadius: '0.3rem'
  },
  customLegendBox:{
    position:'absolute',
    top:-45,
    right:12
  },
  customLegend: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  customTooltip: {
    background: '#4A5568',
    color: '#FFFFFF',
    padding: '0.8rem',
    borderRadius: '0.5rem',
    boxShadow: '0px 1px 4px 0px #2C405A35',
    pointerEvents: 'none',
    zIndex: 1000,
    transition: 'left 0.1s, top 0.1s', // Add smooth transition

  },
  customArrow: {
   width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid #4A5568',
    position: 'absolute',
    left: '50%',
    bottom: '-6px',
    transform: 'translateX(-50%)'
  },
  labelName:{
    fontSize:'1rem',
    '@media (min-width:2560px)': {
      fontSize: '1.5rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem',
    },
  },
  axisLabels:{
    fontSize:'0.7rem',
    '@media (min-width:2560px)': {
      fontSize: '1.2rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.5rem',
    },
  },
  labelText:{
    fontSize:'0.65rem',
    '@media (min-width:2560px)': {
      fontSize: '1.1rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.4rem',
    },
  }

}));

const OVERLAP_THRESHOLD = 100; // Adjust this value as needed

const CustomXAxisLabel = ({classes, viewBox,startDate,endDate }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'));
  const isXlScreen = useMediaQuery(theme.breakpoints.only('xl'));

  const width = window.innerWidth;


  // console.log("width ",width)
  let heightValue;
  if(width >= 1536 && width <= 1919){
    heightValue=430
  }
  if(width >= 900 && width <= 960){
    heightValue=400
  }
  if(width >= 2560){
    heightValue=408
  }
  else if (isXsScreen) {
    heightValue = 300;
  } else if (isSmScreen) {
    heightValue = 340;
  } else if (isMdScreen) {
    heightValue = 380;
  } else if (isLgScreen) {
    heightValue = 400;
  } else if (isXlScreen) {
    heightValue = 440;
  } 
  else {
    heightValue = 350; // Default value
  }

  return (
    <g transform={`translate(${viewBox.width / 2 + 100},${viewBox.height/2 + heightValue})`}>
      <text className={classes.labelName} x={0} y={0} dy={16} textAnchor="middle" fill="#000000">
        Duration
      </text>
      {startDate && <text className={classes.labelText} x={-viewBox.width / 2+60} y={-18} dy={18} textAnchor="middle" fill="#909097">
        {startDate}
      </text>}
      {endDate&&<text className={classes.labelText} x={viewBox.width / 2-100} y={-18} dy={18} textAnchor="middle" fill="#909097">
        {endDate}
      </text>}
   

    </g>
  );
};

const CustomYAxisLabel = ({classes, viewBox, maxValue }) => {
  const showThousandsText = maxValue >= 1000;

   // Determine the label text based on minValue
   let labelText = '';
    if (maxValue >= 10000000) {
      labelText = 'in crores'; // Text for values >= 999999
    }
    else if (maxValue >= 100000) {
      labelText = 'in lakhs'; // Text for values >= 999999
    }
    else if ( maxValue >=1000) {
      labelText = 'in thousands'; // Text for values >= 99999
    } 
    else if (maxValue >=100) {
      labelText = 'in hundreds'; // Text for values >= 999
    } 
    
   else if (maxValue <= 99) {
     labelText = ''; // No text for values >= 99
   }

 

  return (
    <g transform={`translate(${0},${viewBox.height / 2})`}>
      <text className={classes.labelName} x={0} y={0} dy={16} textAnchor="middle" fill="#000000" transform="rotate(-90)">
        Count
      </text>
      {labelText && (
        <text className={classes.labelText} x={6} y={14.5} dy={16} textAnchor="middle" fill="#000000" transform="rotate(-90)">
          {labelText}
        </text>
      )}
    </g>
  );
};


const CustomDot = ({ cx, cy, value, name, payload, setTooltipData }) => {
  const isOverlapping = Math.abs(payload.total_count - payload.attacks) < OVERLAP_THRESHOLD;

  return (
    <circle
      cx={cx}
      cy={cy}
      r={6}
      fill="#FAB446"
      stroke="#FFFFFF"
      strokeWidth={2}
      style={{ cursor: 'pointer' }}
      onMouseEnter={(e) => {
        if (isOverlapping) {
          // Use Recharts tooltip for overlapping points
          return;
        }
        // Use custom tooltip for non-overlapping points
        setTooltipData({
          show: true,
          x: e.clientX,
          y: e.clientY,
          name,
          value,
          time: payload?.end_time
        });
      }}
      onMouseLeave={() => {
        if (!isOverlapping) {
          setTooltipData({ show: false });
        }
      }}
    />
  );
};

const CustomLegend = () => {
  const classes = useStyles();
  return (
    <div className={classes.customLegend}>
      <div className={classes.legendItem}>
        <div className={classes.legendColorBox} style={{ backgroundColor: '#4F46BB' }} />
        <Typography variant="body2">Traffic</Typography>
      </div>
      <div className={classes.legendItem}>
        <div className={classes.legendColorBox} style={{ backgroundColor: '#FF4B55' }} />
        <Typography variant="body2">Attacks</Typography>
      </div>
    </div>
  );
};

const CustomTooltip = ({ active, payload, coordinate, classes }) => {
  if (active && payload && payload.length) {
    const isOverlapping = Math.abs(payload[0].value - payload[1].value) < OVERLAP_THRESHOLD;

    if (isOverlapping) {
      return (
        <div 
          className={classes.customTooltip}
          style={{
            left: coordinate.x,
            top: coordinate.y - 50,
            transform: 'translate(-50%, -100%)',
          }}
        >
          <p>Traffic: {formatNumber(payload[0].value)}</p>
          <p>Attacks: {formatNumber(payload[1].value)}</p>
          <div className={classes.customArrow} />
        </div>
      );
    }
  }
  return null;
};

const formatCustomLabel = (date,diffDays) =>{
  if (diffDays < 2) {
    return format(date, 'dd/MM hh a');
  } else if (diffDays >= 2 && diffDays <= 10) {
    return format(date, 'dd/MM hh a');
  } else if (diffDays >= 11 && diffDays <= 31) {
    return format(date, 'MMM/dd hh a');
  } else if (diffDays >= 32 && diffDays <= 180) {
    return format(date, 'dd/MMM');
  } else {
    return format(date, 'MMM dd'); // Default format
  }
}


const AttackTrendlineChart = ({ startAndEndDate,data,label }) => {
  const classes = useStyles();
  const [tooltipData, setTooltipData] = useState({ show: false, x: 0, y: 0, name: '', value: 0, time: '' });

  const diffInDays = getDaysDifference(startAndEndDate.from, startAndEndDate.to);

  const hasTotalCount = data?.some(item => item?.total_count > 0);

  let startDate = ''
  let endDate = ''

  if(data?.length !==0 && label === '12h' || label === '24h'){
    startDate = dateSlashFormat(data[0]?.start_time)
    endDate = dateSlashFormat(data[data?.length -1]?.end_time)
  }
  
  // Calculate the minimum Y value
  const maxValue = useMemo(() => {
    if (!data || data?.length === 0) return 0;
    return Math.max(
      ...data?.map(item => Math.max(item?.total_count || 0, item?.attacks || 0))
    );
  }, [data]);

  // console.log("maxValue ",maxValue)

  
  const formatXAxis = (tickItem: any, index: number) => {
    const date = new Date(tickItem);
    const start = startAndEndDate.from
    const end = startAndEndDate.to

    const diffDays = getDaysDifference(start, end);

    let formattedDate: Date;
    let formatString: string;

   
  

    switch (label) {
      case '12h':
      case '24h':
        formatString = 'hh a';

      // formattedDate = addHours(startOfHour(date), 0);
        return format(date, 'hh a');
      case 'week':
        // formattedDate = startOfDay(date);
        formatString = 'dd/MM';
        return  format(date, formatString);
      case 'month':
        // formattedDate = startOfDay(date);
        formatString = 'MMM/dd';
        return  format(date, formatString);
      case 'year':
        // formattedDate = startOfMonth(date);
        formatString = 'dd/MMM/yyyy';
        return  format(date, formatString);
      default:
        if (diffDays < 2) {
          // formattedDate = addHours(startOfHour(date), 1);

          formatString = 'dd/MM hha';
        } else if (diffDays >= 2 && diffDays <= 10) {
          // formattedDate = startOfDay(date);
          formatString = 'dd/MM';
        } else if (diffDays >= 11 && diffDays <= 32) {
          // formattedDate = startOfDay(date);
          formatString = 'dd/MMM';
        } else if (diffDays >= 32 && diffDays <= 180) {
         
          // formattedDate = addMonths(startOfMonth(date), 0);
          formatString = 'dd/MMM';
        } else {
          // formattedDate = addMonths(startOfMonth(date), 0);
          formatString = 'dd/MMM/yyyy';
        }

        return format(date, formatString);

    }
  
    // // Adjust for start and end of range if necessary
    // if (isSameDay(date, start)) {
    //   formattedDate = startOfHour(start);
    // } else if (isSameDay(date, end)) {
    //   formattedDate = addHours(startOfHour(end), 1);
    // }
  
  };

  const theme = useTheme();
  
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

  // const width = window.innerWidth;

  let interval = 1
  let angle = 0


  if(label === '12h'){
    interval = 0
    
  }
  if(label === '24h'){
    interval = 0
    angle=-15
    if(isLgScreen){
      angle= 0
    }
  }
   else if(label === 'week'){
    interval = 0
   }
   else if(label === 'month'){
    interval = 0
    angle=-35
   }
   else if(label === 'year'){
    interval = 0
    angle=-10
    if(isLgScreen){
      angle=0
    }
    
   }
   else if(label === 'custom'){
    interval = 0
    angle=-40
    if(diffInDays < 2){
      angle=-20
    }
    else if(diffInDays >=180){
      angle=0
    }
   }

   const calculateTicks = (data) => {
    const length = data.length;
    if (length <= 10) return data.map(item => item.end_time);
    
    let step = Math.max(Math.floor(length / 12), 1); // Show about 10 ticks, but adjust based on data length
    
    let ticks = [data[0].end_time];
    for (let i = step; i < length - 1; i += step) {
      ticks.push(data[i].end_time);
    }
    ticks.push(data[length - 1].end_time);
    
    return ticks;
  };


  if(!hasTotalCount){
    return <Box sx={{display:'flex',height:'80%',justifyContent:'center',alignItems:'center'}}>
      <NoAttacksFound id="noAttacksTrendlineFound"/>
    </Box>
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.customLegendBox}>
        <CustomLegend />
      </Box>
      {tooltipData?.show && (
        <div
          className={classes.tooltip}
          style={{
            left: `${tooltipData.x}px`,
            top: `${tooltipData.y-10}px`,
            transform: 'translate(-50%, -100%)', // Center the tooltip above the cursor
          }}
          // style={{
          //   left: tooltipData.x + window.scrollX,
          //   top: tooltipData.y + window.scrollY
          // }}
        >
          <p>{`${formatNumber(tooltipData?.value)}`}</p>
          <div className={classes.arrow}/>
        </div>
      )}
      <div className={classes.chartContainer}>

     <ResponsiveContainer width="100%" height={"100%"}>
     {data?.length > 0 ?<ComposedChart data={data} margin={{ top: 20, right: 55, left: 20, bottom: 40 }}>
          <defs>
            <linearGradient id="colorTraffic" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(0, 70, 255, 0.32)" stopOpacity={1} />
              <stop offset="100%" stopColor="rgba(79, 70, 187, 0)" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="colorAttacks" x1="0" y1="0" x2="0" y2="1">
              <stop offset="-19.4%" stopColor="rgba(255, 75, 85, 0.32)" stopOpacity={1} />
              <stop offset="100%" stopColor="rgba(79, 70, 187, 0)" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} horizontal={true} stroke="#e0e0e0" />
          <YAxis domain={['auto', 'auto']} 
              tickCount={7} axisLine={false}
              tickFormatter={(value) => formatNumber(value)} 
              tickLine={false} 
              label={<CustomYAxisLabel viewBox={undefined} classes={classes} maxValue={maxValue} />}
              className={classes.axisLabels}

              />
         
          <Area type="linear" dataKey="total_count" fill="url(#colorTraffic)" stroke="none" legendType="none" />
          <Area type="linear" dataKey="attacks" fill="url(#colorAttacks)" stroke="none" legendType="none" />
      
          <XAxis 
            dataKey="end_time" 
            axisLine={false} 
            tickLine={false} 
            tickMargin={12} 
            angle={angle}
            tickFormatter={formatXAxis} 
            className={classes.axisLabels}
            label={<CustomXAxisLabel classes={classes} viewBox={undefined} startDate={startDate} endDate={endDate}/>}
            ticks={calculateTicks(data)}  // Use calculated ticks
            interval={interval}  // Force display of all ticks
            // ticks={data.filter((_, index) => index % 1 === 0).map(item => item.end_time)}  // Show every other tick
            />

          <Line
            type="linear"
            dataKey="total_count"
            stroke="#4F46BB"
            dot={(props) => (
              <CustomDot
                {...props}
                name="Traffic"
                setTooltipData={setTooltipData}
              />
            )}
            strokeWidth={4}
            name="Traffic"
          />

          <Line
            type="linear"
            dataKey="attacks"
            stroke="#FF4B55"
            dot={(props) => (
              <CustomDot
                {...props}
                name="Attacks"
                setTooltipData={setTooltipData}
              />
            )}
            strokeWidth={4}
            name="Attacks"
          />
        {tooltipData.show && (
            <div
              className={classes.tooltip}
              style={{
                left: `${tooltipData.x}px`,
                top: `${tooltipData.y-10}px`,
                transform: 'translate(-50%, -100%)',
              }}
            >
              <p>{`${formatNumber(tooltipData.value)}`}</p>
              <div className={classes.arrow}/>
            </div>
          )}
          {/* <Tooltip 
          /> */}
          <Tooltip 
            cursor={false}
            content={<CustomTooltip classes={classes} active={undefined} payload={undefined} coordinate={undefined} />} 
          />
        </ComposedChart>:
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <NoAttacksFound id={'NoAttacksForTrendline'}/> 
        </Box>
          }

       
      </ResponsiveContainer>
      </div>
    </Box>
    
  );
};

export default AttackTrendlineChart;
