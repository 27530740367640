import React from 'react'

import NoDataFound from '../assets/icons/svg/noDataImg.svg';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    noDataBox:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      height:'100%'
    },
    image:{
        width:'70%',height:'100%'
    }
}))

const NoAttacksFound = ({id}) => {
    const classes = useStyles()
  return (
    <Box className={classes.noDataBox} id={id} >
        <img className={classes.image} src={NoDataFound} alt="noDataAvailabel"/>
    </Box>
  )
}

export default NoAttacksFound
